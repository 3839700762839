import React from 'react';
import successIcon from '../law_profile/success.svg';
import './style/register.scss';

const SignUpRequestSuccess = (props) => {
  return (
    <div className="SignUp">
      <div className="signup-header">
        <img style={{ marginTop: '3em' }} className="side-navbar-home-icon" src={successIcon} alt="" />
        <h1 style={{ color: '#4CD964', fontSize: '2em' }}className="signup-title">Verification in progress</h1>
        <p style={{ color: '#000000', fontSize: '1.2em', fontWeight: '400', width: '350px' }}>Please give our team a maximum of 48 hours to complete the vetting process.</p>
        <a href='https://counselconnect.ca/'>
        <button type="submit" style={{marginTop: '2em'}} className="signup-button">RETURN HOME</button>
        </a>
      </div>
    </div>
  );
};


export default SignUpRequestSuccess;
