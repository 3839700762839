import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import http from "../../utils/http";
import useForm from "../../hooks/useForm";
import { registerUser } from "../../actions/authActions";
import TextField from "../reusable/text_field/TextField";
import "./style/register.scss";
import logo from "./images/logo.svg";
import { withAlert } from "react-alert";
import environment from "../../utils/environment";

// TODO: errors
const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password1: "",
  password2: "",
};

// const validate = (values) => {
//   const { firstName, lastName, email, password1, password2 } = values;

//   return (
//     firstName.length > 0
//     &&  lastName.length > 0
//     && email.length > 0
//     && password1.length > 0
//     && password2.length > 0
//   );
// };

const SignUp = (props) => {
  const { shortId } = props.match.params;
  const { auth, history } = props;

  const onSubmit = async (inputs) => {
    const signUpData = {
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      email: inputs.email,
      password: inputs.password1,
    };

    if (signUpData.firstName.length === 0 || signUpData.lastName.length === 0) {
      props.alert.error("Name field is empty!");
      return;
    }

    if (!(inputs.password1 === inputs.password2)) {
      props.alert.error("Password Don't Match!");
      return;
    }

    try {
      await http.post(
        `${environment.resolveApi().rest}/user/register/${shortId}`,
        signUpData
      );
      props.history.push("/");
      props.alert.success("Account Created!");
    } catch (error) {
      console.log(error);
      if (error.message) {
        props.alert.error(error.message);
      }
      if (error.email) {
        props.alert.error(error.email);
      }
      if (error.password) {
        props.alert.error(error.password);
      }
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(
    onSubmit,
    initialState
  );

  const fetchToken = async () => {
    try {
      await http.get(`${environment.resolveApi().rest}/mailing/${shortId}`);
    } catch (error) {
      console.log(error);
      history.push("/");
    }
  };

  useEffect(() => {
    fetchToken();
    if (auth.isAuthenticated) {
      history.push("/");
    }
  }, [auth, history]);

  return (
    <div className="SignUp">
      <div className="signup-header">
        <img className="side-navbar-home-icon" src={logo} alt="" />
        <h1 className="signup-title">
          You’ve been verified! Please create an account to continue
        </h1>
        <p>
          Join us to grow your practice, connect with clients, and expand your
          network
        </p>
      </div>

      <form onSubmit={handleSubmit} className="signup-form-container">
        <TextField
          className="signup-input"
          title="First Name"
          placeholder=""
          name="firstName"
          type="name"
          value={inputs.firstName}
          onChange={handleInputChange}
          // error={errors.lastName}
          autoComplete="off"
        />

        <TextField
          className="signup-input"
          title="Last Name"
          placeholder=""
          name="lastName"
          type="name"
          value={inputs.lastName}
          onChange={handleInputChange}
          // error={errors.lastName}
          autoComplete="off"
        />

        <div className="input-container">
          <TextField
            className="signup-input"
            title="Email"
            placeholder=""
            name="email"
            type="email"
            value={inputs.email}
            onChange={handleInputChange}
            // error={errors.email}
            autoComplete="off"
          />
        </div>
        <div className="input-container">
          <TextField
            className="signup-input"
            title="Password"
            placeholder=""
            name="password1"
            type="password"
            value={inputs.password1}
            onChange={handleInputChange}
            // error={errors.password}
            autoComplete="off"
          />
        </div>
        <div className="input-container">
          <TextField
            className="signup-input"
            title="Confirm Password"
            placeholder=""
            name="password2"
            type="password"
            value={inputs.password2}
            onChange={handleInputChange}
            // error={errors.password}
            autoComplete="off"
          />
        </div>

        <button type="submit" className="signup-button">
          SIGN UP
        </button>
        <p className="consent-txt">
          By submitting your contact information, you agree to our Terms of
          Service and Privacy Policy and consent to having CounselConnect
          contact you by text message and email.
        </p>
      </form>

      <p className="text-muted copyright-signup">
        Copyright &copy; 2023 by CounselConnect
      </p>
    </div>
  );
};

SignUp.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ auth: state.auth, errors: state.errors });

export default withAlert()(
  connect(mapStateToProps, { registerUser })(withRouter(SignUp))
);
