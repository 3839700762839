import React, { useEffect, useState, useRef, useCallback } from 'react';
import useForm from '../../../hooks/useForm';
import http from '../../../utils/http';
import TextField from '../../reusable/text_field/TextField.jsx';
import SelectList from '../../reusable/select_list/SelectList';
import TextAreaField from '../../reusable/text_area_field/TextAreaField';
import defaultPic from './employee_default_pic.svg';
import Modal from '../../reusable/Modal';
import { withAlert } from "react-alert";
import environment from '../../../utils/environment';
import MicroModal from 'micromodal';
import spinner from './spinner_2.gif';

import './NewEmployeeModal.scss';


const DeleteEmployeeModal = (props) => {

  // const onModalClose = useCallback(()=> MicroModal.close('delete-employee'), [])

 


  const deleteEmployee = async (employee_handle) => {
    try {
      await http.post(`${environment.resolveApi().rest}/employee_profile/`, { employee_handle });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }






    return (

  <div className="NewEmployeeModal" >

   
    <Modal  modalId={props.modalId} >
    


          <div className="delete-emp-modal-container">
          

          <img className=" delete-emp-img" src={props.employeeDeleteData.profileImg ? props.employeeDeleteData.profileImg : ''} alt="" />


            <h1 className="delete-emp-title">Are you sure you want to remove {`${props.employeeDeleteData.firstName}`}'s profile?</h1>
            <div className="modal-btn-group">
            <div >
                <button className="modal-btn-red" onClick={()=>{deleteEmployee(props.employeeDeleteData.handle)}}>Yes</button>
              </div>
              <div className="">
                <button className="modal-btn-default" onClick={()=>{MicroModal.close('delete-employee')}}>No</button>
              </div>
            </div>
          </div>


      </Modal>
  </div>

    );
 
}


export default withAlert()(DeleteEmployeeModal);
