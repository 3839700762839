import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import TextField from "../../reusable/text_field/TextField.jsx";
import SelectList from "../../reusable/select_list/SelectList";
import http from "../../../utils/http";
import useForm from "../../../hooks/useForm";
import defaultPic from "../edit_employees/employee_default_pic.svg";
import { withAlert } from "react-alert";
import environment from "../../../utils/environment";
import spinner from "../edit_employees/spinner_2.gif";

import "../edit_profile/EditProfile.scss";
import "./createProfile.scss";
import { PlanSelectedContext } from "../../../contexts/PlanSelectedContext.js";
import { PlanTypeContext } from "../../../contexts/PlanTypeContext.js";

// Select years of experience options for professional status
const citiesOptions = [
  { label: "* Select City", value: 0 },
  { label: "Toronto", value: "Toronto" },
  { label: "Mississauga", value: "Mississauga" },
  { label: "Ottawa", value: "Ottawa" },
  { label: "Burlington", value: "Burlington" },
  { label: "Brampton", value: "Brampton" },
  { label: "Kitchener", value: "Kitchener" },
  { label: "Markham", value: "Markham" },
  { label: "Scarborough", value: "Scarborough" },
  { label: "Sudbury", value: "Sudbury" },
  { label: "Windsor", value: "Windsor" },
  { label: "Hamilton", value: "Hamilton" },
  { label: "London", value: "London" },
  { label: "Kingston", value: "Kingston" },
];

const timeOptions = [
  { label: "* Select Time", value: 0 },
  { label: "Closed", value: "Closed" },
  { label: "6:00 AM", value: "6:00 AM" },
  { label: "7:00 AM", value: "7:00 AM" },
  { label: "8:00 AM", value: "8:00 AM" },
  { label: "9:00 AM", value: "9:00 AM" },
  { label: "10:00 AM", value: "10:00 AM" },
  { label: "11:00 AM", value: "11:00 AM" },
  { label: "12:00 PM", value: "12:00 PM" },
  { label: "1:00 PM", value: "1:00 PM" },
  { label: "2:00 PM", value: "2:00 PM" },
  { label: "3:00 PM", value: "3:00 PM" },
  { label: "4:00 PM", value: "4:00 PM" },
  { label: "5:00 PM", value: "5:00 PM" },
  { label: "6:00 PM", value: "6:00 PM" },
  { label: "7:00 PM", value: "7:00 PM" },
  { label: "8:00 PM", value: "8:00 PM" },
  { label: "9:00 PM", value: "9:00 PM" },
  { label: "10:00 PM", value: "10:00 PM" },
];

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const CreateProfile = (props) => {
  const { errors } = props;

  const PLAN_SELECTED_CONTEXT = useContext(PlanSelectedContext);
  const { planType, setPlanType } = useContext(PlanTypeContext);

  const onSubmit = async (inputs) => {
    const availabilityData = {
      ...days.reduce(
        (acc, day) => ({
          ...acc,
          [day]: {
            from: inputs[`${day}from`],
            to: inputs[`${day}to`],
          },
        }),
        {}
      ),
    };

    console.log(availabilityData);

    if (
      !availabilityData.monday.from ||
      !availabilityData.monday.to ||
      !availabilityData.tuesday.from ||
      !availabilityData.tuesday.to ||
      !availabilityData.wednesday.from ||
      !availabilityData.wednesday.to ||
      !availabilityData.thursday.from ||
      !availabilityData.thursday.to ||
      !availabilityData.friday.from ||
      !availabilityData.friday.to ||
      !availabilityData.saturday.from ||
      !availabilityData.saturday.to ||
      !availabilityData.sunday.from ||
      !availabilityData.sunday.to
    ) {
      props.alert.error("Missing Office Hours");
      return;
    }

    const profileData = {
      companyLogo: picUrl,

      name: inputs.name,

      website: inputs.website,
      linkedin: inputs.linkedin,

      city: inputs.city,
      postalcode: inputs.postalcode,
      address: inputs.address,
      phonenumber: inputs.phonenumber,

      office_hours: availabilityData,
      plan: planType,
    };
    console.log(profileData);

    try {
      await http.post(`${environment.resolveApi().rest}/law_firm`, profileData);
      props.alert.success("Law Firm Profile Created!");
      // props.history.push("/");
      window.location.reload();
    } catch (error) {
      if (error.name) {
        props.alert.error(error.name);
      }
      if (error.city) {
        props.alert.error(error.city);
      }
      if (error.phonenumber) {
        props.alert.error(error.phonenumber);
      }
      if (error.postalcode) {
        props.alert.error(error.postalcode);
      }
      if (error.address) {
        props.alert.error(error.address);
      }
      if (error.companyLogo) {
        props.alert.error(error.companyLogo);
      }
    }
  };

  const { inputs, setInputs, handleInputChange, handleSubmit } =
    useForm(onSubmit);

  const [picUrl, setPictureUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const uploadRef = useRef(null);

  const onImageClick = useCallback(() => {
    uploadRef.current.click();
  }, [uploadRef]);

  const onFileUpload = async (e) => {
    e.persist();

    const file = e.target.files[0];

    //  If the file size (calculated in byte form) is than 1MB
    if (file.size > 1.0e6) {
      return props.alert.error("IMAGE SIZE MUST BE LESS THAN 1MB");
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      return props.alert.error("IMAGE TYPE MUST BE PNG OR JPG");
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);

      const response = await http.uploadImage(
        `${environment.resolveApi().rest}/law_firm/uploadNew`,
        formData
      );

      setLoading(false);

      setPictureUrl(response.data.pictureURL);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // console.log("planType", planType);
  }, []);

  return (
    <div className="EditProfile">
      {loading ? (
        <div
          style={{ display: "flex", alignItems: "center", width: "100%" }}
          className="modal-spinner-container"
        >
          <h2 className="modal-spinner-title">Setting Profile Image</h2>
          <img
            className="modal-loading-spinner"
            src={spinner}
            alt="Loading..."
          />
        </div>
      ) : (
        <div className="create-profile-form-container">
          <button
            onClick={() => {
              PLAN_SELECTED_CONTEXT.setPlanSelected(false);
            }}
          >
            Go back to plans
          </button>
          <form onSubmit={handleSubmit}>
            <div className="edit-profile-container-3">
              <span className="edit-profile-c3-title">General Info</span>
              <hr style={{ width: "100%" }}></hr>

              <div className="edit-profile-c2-input-field-container">
                <div
                  className={"new-lawfirm-modal-top-w-profile-pic-w"}
                  onClick={onImageClick}
                >
                  <img
                    className="new-lawfirm-uploaded-pic"
                    src={picUrl ? picUrl : defaultPic}
                    alt=""
                  />
                  <input type="file" onChange={onFileUpload} ref={uploadRef} />
                </div>

                <div className="edit-profile-c3-input-group">
                  <TextField
                    title="Law Firm Name"
                    placeholder=""
                    name="name"
                    value={inputs.name}
                    onChange={handleInputChange}
                    info="Company Name"
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c3-input-group">
                  <TextField
                    title="Website"
                    placeholder=""
                    name="website"
                    value={inputs.website}
                    onChange={handleInputChange}
                    info="Law firm website URL."
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c3-input-group">
                  <TextField
                    title="Linkedin"
                    placeholder=""
                    name="linkedin"
                    value={inputs.linkedin}
                    onChange={handleInputChange}
                    info="Law firm linkedin profile URL."
                    lawPortal
                  />
                </div>
              </div>
            </div>

            <div className="edit-profile-container-4">
              <span className="edit-profile-c4-title">Contact Info</span>
              <hr style={{ width: "100%" }}></hr>
              <div className="edit-profile-c4-input-field-container">
                <div className="edit-profile-c4-input-group">
                  <SelectList
                    title="City"
                    placeholder=""
                    name="city"
                    value={inputs.city}
                    onChange={handleInputChange}
                    options={citiesOptions}
                    info="City that you live in."
                    className="law-portal-select-list-input"
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c4-input-group">
                  <TextField
                    title="Postal Code"
                    placeholder=""
                    name="postalcode"
                    value={inputs.postalcode}
                    onChange={handleInputChange}
                    info="Postal Code."
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c4-input-group">
                  <TextField
                    title="Address"
                    placeholder=""
                    name="address"
                    value={inputs.address}
                    onChange={handleInputChange}
                    info="Work Address."
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c4-input-group">
                  <TextField
                    title="Phone Number"
                    placeholder=""
                    name="phonenumber"
                    value={inputs.phonenumber}
                    onChange={handleInputChange}
                    info="Personal or law firm number."
                    lawPortal
                  />
                </div>
              </div>

              <div className="edit-profile-container-4">
                <span className="edit-profile-c4-title">Office Hours</span>
                <hr style={{ width: "100%" }}></hr>

                <div className="edit-avail-form-container-create-profile">
                  {days.map((day) => (
                    <div
                      key={day}
                      className="edit-avail-input-container-1-create-profile"
                    >
                      <h3 className="edit-avail-day-text">
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                      </h3>
                      <div className="edit-avail-select-container-create-profile">
                        <div className="edit-avail-select-input-1">
                          <SelectList
                            className="law-portal-select-list-input law-portal-select-list-input-create-profile"
                            title="From"
                            placeholder=""
                            name={`${day}from`}
                            value={inputs[`${day}from`]}
                            onChange={handleInputChange}
                            options={timeOptions}
                            info=""
                            lawPortal
                          />
                        </div>
                        <div className="edit-avail-select-input-2">
                          <SelectList
                            className="law-portal-select-list-input law-portal-select-list-input-create-profile"
                            title="To"
                            placeholder=""
                            name={`${day}to`}
                            value={inputs[`${day}to`]}
                            onChange={handleInputChange}
                            options={timeOptions}
                            info=""
                            lawPortal
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="edit-profile-update-button-container">
              <button type="submit" className="edit-profile-update-button">
                CREATE PROFILE
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default withAlert()(CreateProfile);
