import React, { useState, useEffect, useContext } from "react";

import ccHeart from "../images/cc-prem-heart.svg";
import http from "../../../utils/http";
import environment from "../../../utils/environment";
// import "../Navbar.scss";
import StripeConfig from "../../../stripe_config.json";
import { PlanSelectedContext } from "../../../contexts/PlanSelectedContext";

const ProductDisplay = (props) => {
  let { planType, flow } = props;
  const RedirectLink = (url) => {
    window.location.replace(url);
  };

  const onCheckout = async () => {
    try {
      console.log("hi");
      let response = await http.post(
        `${environment.resolveApi().rest}/payments/create-checkout-session`,
        {
          priceId:
            planType == "Monthly"
              ? environment.resolveApi().rest == "http://localhost:5001/api"
                ? StripeConfig.cc_premium_dev
                : StripeConfig.cc_premium_prod
              : environment.resolveApi().rest == "http://localhost:5001/api"
              ? StripeConfig.cc_premium_dev_ANNUAL
              : StripeConfig.cc_premium_prod_ANNUAL,
          flow,
          type: "premium",
        }
      );

      let sessionUrl = response.data.sessionUrl;

      console.log("sessionUrl", sessionUrl);
      RedirectLink(sessionUrl);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className=" ">
      <div className="navbar__links__ccPremiumTxtWrap">
        {/* <img src={ccHeart} className="navbar__links__ccPremium__icon" /> */}
        {/* <span className="navbar__links__ccPremium__label">PREMIUM</span> */}
      </div>

      <button
        className="navbar__links__ccPremium__btn"
        onClick={() => {
          onCheckout();
        }}
      >
        Upgrade Now
      </button>
    </div>
  );
};

const SuccessDisplay = (props) => {
  let { sessionId, setSuccess, setUserHasPrem, flow } = props;
  const PLAN_SELECTED_CONTEXT = useContext(PlanSelectedContext);

  const RedirectLink = (url) => {
    window.location.replace(url);
  };

  const onManageBilling = async () => {
    try {
      console.log("trigger --> manage billing - sessionId: ", sessionId);
      let response = await http.post(
        `${environment.resolveApi().rest}/payments/create-portal-session`,
        { session_id: sessionId, flow }
      );

      let portalURL = response.data.portalUrl;
      console.log("response", response);

      RedirectLink(portalURL);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    PLAN_SELECTED_CONTEXT.setPlanSelected(true);
  }, []);

  return (
    <div className="navbar__links__ccPremium navbar__links__ccPremium--manage">
      <div className="navbar__links__ccPremiumTxtWrap">
        <img src={ccHeart} className="navbar__links__ccPremium__icon" />
        <span className="navbar__links__ccPremium__label">CURRENT PLAN</span>
      </div>
    </div>
  );
};

const CC__PremiumBtn = (props) => {
  let { setOnHasPremium } = props;
  // let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  let [userHasPrem, setUserHasPrem] = useState(false);

  const getUser = async () => {
    try {
      let response = await http.get(
        `${environment.resolveApi().rest}/payments/is-user-premium`
      );

      console.log("getUser", response);

      setUserHasPrem(response.data.has_premium);
      setOnHasPremium(response.data.has_premium);
      // if user has premium
      if (response.data.has_premium) {
        setSessionId(response.data.session_id);
        setSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addPremium = async ({ sessionId }) => {
    try {
      let response = await http.post(
        `${environment.resolveApi().rest}/payments/add-premium`,
        {
          session_id: sessionId,
        }
      );

      console.log("response", response);
      getUser();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();

    // if the user has premium
    if (userHasPrem) {
      console.log("has premium");
      // set the session Id
      // setSessionId("");
    } else {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search);

      if (query.get("success")) {
        // setSuccess(true);
        // setSessionId(query.get("session_id"));
        // addPremium({ sessionId: query.get("session_id") });
      }

      if (query.get("canceled")) {
        setSuccess(false);
        // setMessage("");
      }
    }
  }, [sessionId]);

  if (!success && !userHasPrem) {
    return <ProductDisplay {...props} />;
  } else if (success && sessionId !== "" && userHasPrem) {
    return (
      <SuccessDisplay
        sessionId={sessionId}
        setUserHasPrem={setUserHasPrem}
        setSessionId={setSessionId}
        setSuccess={setSuccess}
      />
    );
  } else {
    return <></>;
  }
};
export default CC__PremiumBtn;
