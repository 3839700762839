import React, { useState } from 'react';
import './location.scss';
import { withAlert } from "react-alert";

const paralegalFields = [
  { id: 1, value: 'Traffic Tickets' },
  { id: 2, value: 'Minor Offences' },
  { id: 3, value: 'Landlord or Tenant' },
  { id: 4, value: 'Employment Law' },
  { id: 5, value: 'Small Claims' },
  { id: 6, value: 'Immigration Law' },
];

const arr = [];

const Paralegal = (props) => {
  const { city, status } = props.location.state;
  // console.log(city, status);

  const [lawFields, setLawFields] = useState(arr);

  const onSubmitFields = () => {
    if(arr.length === 0) {
      props.alert.error("Select a category!");
    } else {
      setLawFields(arr);
      props.history.push({pathname: `/search_by_gender`, state: { city: city, status: status, field: lawFields }});
    }
  };

  const btnClick = (event) => {
    if (event) event.preventDefault();
    // const index = arr.indexOf(event.currentTarget.value);
    arr.push(event.currentTarget.value);
    onSubmitFields();
    // if (index > -1) {
    //   arr.splice(index, 1);
    // } else {
    //   arr.push(event.currentTarget.value);
    // }
  };

  return (
    <div className="Paralegal-Search">
      <h1 className="location-search-title">Paralegal Categories</h1>
      <p className="location-search-subtitle">Select the law field that best fits your needs.</p>
      <div className="law-field-options">
        {paralegalFields.map((field, index) => (
          <button type="submit" className="status-search-lawyer-button" onClick={btnClick} key={index.id} value={field.value}>{field.value}</button>
        ))}
      </div>
      {/* <Link to={{ state: { city: city, status: status, field: lawFields } }}>
        <button type="submit" className="location-search-button" onClick={onSubmitFields}>
              Continue
          {' '}
          <i className="fas fa-play" />
        </button>
      </Link> */}
    </div>
  );
};

export default withAlert()(Paralegal);
