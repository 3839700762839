import React, { useContext, useEffect, useState } from "react";

import * as Icon from "react-feather";
import "./ClientInfo.scss";
import { ModalWrapper } from "../../reusable/modal_wrapper/ModalWrapper";
import dateFormat, { masks } from "dateformat";
import LETTER_COLORS from "../../reusable/LetterColors";

import { PlanUsedUpContext } from "../../../contexts/PlanUsedUpContext";

const ClientInfo = (props) => {
  let { client, oldestConnection__index } = props;

  // console.log("oldestConnection__index", oldestConnection__index);
  const [openModal, setOpenModal] = useState(false);
  const [modalView, setModalView] = useState("upgrade");

  const { planUsedUp, setPlanUsedUp } = useContext(PlanUsedUpContext);

  if (client && !client.visible) {
    return (
      <div className="client-info" id="modal">
        <ModalWrapper
          isOpen={openModal}
          onRequestClose={() => {
            setModalView("upgrade");
            setOpenModal(false);
          }}
          modalView={modalView}
          headerLabel={""}
        />
        <div className="clients-list__list__card-left-container">
          <div className="clientInfo__letterNameWrap">
            <div
              className="clientInfo__client__letterWrap"
              style={{
                backgroundColor: client.first_name
                  ? LETTER_COLORS[client.first_name.charAt(0).toUpperCase()]
                      .background
                  : "",
              }}
            >
              <span className="clients-list__list__card__profile-circle__txt">
                {client.first_name && client.first_name.charAt(0)}
              </span>
            </div>

            <span className="clientInfo__client__name">
              {client.first_name}
            </span>
          </div>

          <div className="clientList__lawyerArrowWrap">
            <Icon.ArrowRight size={21} color={"#3B1212"} />

            <div className="clientList__lawyer">
              <img
                className="clientList__lawyer__img"
                src={client.lawyer && client.lawyer.picture_url}
              />

              <span className="clientInfo__lawyer__name">
                {client.lawyer && client.lawyer.first_name}
              </span>
            </div>
          </div>
        </div>

        <hr className="client-info__divider"></hr>

        <div className="client-info__contact-info">
          <div className="client-info__contact-info__email--invalid">
            <Icon.Mail size={21} color={"#9094A1"} />
            <div className="client-info__contact-info__email__txt--invalid"></div>
          </div>
          <div className="client-info__contact-info__phone">
            <Icon.Phone size={21} color={"#9094A1"} />
            <div className="client-info__contact-info__phone__txt--invalid"></div>
          </div>
        </div>

        <hr className="client-info__divider"></hr>

        <div className="client-info__message">
          <span className="client-info__message__txt--invalid">
            Oh no, it looks like you have ran out of client connections, upgrade
            your plan to view the client’s details & message.
          </span>
        </div>

        <button
          className="client-info__upgrade-btn"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Upgrade to view client details
        </button>
      </div>
    );
  }

  return (
    <div className="client-info">
      <div className="clients-list__list__card-left-container">
        <div className="clientInfo__letterNameWrap">
          <div
            className="clientInfo__client__letterWrap"
            style={{
              backgroundColor: client.first_name
                ? LETTER_COLORS[client.first_name.charAt(0).toUpperCase()]
                    .background
                : "",
            }}
          >
            <span className="clients-list__list__card__profile-circle__txt">
              {client.first_name && client.first_name.charAt(0)}
            </span>
          </div>

          <span className="clientInfo__client__name">{client.first_name}</span>
        </div>

        <div className="clientList__lawyerArrowWrap">
          <Icon.ArrowRight size={21} color={"#3B1212"} />

          <div className="clientList__lawyer">
            <img
              className="clientList__lawyer__img"
              src={client.lawyer && client.lawyer.picture_url}
            />

            <span className="clientInfo__lawyer__name">
              {client.lawyer && client.lawyer.first_name}
            </span>
          </div>
        </div>
      </div>

      <hr className="client-info__divider"></hr>

      <div className="client-info__contact-info">
        <div className="client-info__contact-info__email">
          <Icon.Mail size={21} color={"#9094A1"} />
          <span className="client-info__contact-info__email__txt">
            {client.email}
          </span>
        </div>
        <div className="client-info__contact-info__phone">
          <Icon.Phone size={21} color={"#9094A1"} />
          <span className="client-info__contact-info__phone__txt">
            {client.phone_number}
          </span>
        </div>

        {/* <div className="client-info__contact-info__service">
          <Icon.Briefcase size={21} color={"#9094A1"} />
          <span className="client-info__contact-info__phone__txt">
            {client.service_request}
          </span>
        </div> */}
      </div>

      <hr className="client-info__divider"></hr>

      <div className="client-info__message">
        <span className="client-info__message__txt">{client.message}</span>
      </div>
    </div>
  );
};

export default ClientInfo;
