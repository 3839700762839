import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCurrentProfile } from "./actions/profileActions";

import Navbar from "./components/reusable/navbar/Navbar";
import Dashboard from "./components/dashboard/Dashboard";
import CreateProfile from "./components/profile/create-profile/CreateProfile";
import EditProfile from "./components/profile/edit_profile/EditProfile";
import Employees from "./components/profile/edit_employees/Employees";
import Credentials from "./components/profile/add_creds/Credentials.jsx";
import AddAvailability from "./components/profile/edit_avail/AddAvailability.jsx";
import AccountInfo from "./components/info/account_info/AccountInfo";
import Contact from "./components/info/contact/Contact";
import PrivateRoute from "./components/reusable/PrivateRoute";
import NotFound from "./components/reusable/not_found/NotFound";
// import AddEducation from "./components/profile/add_creds/AddEducation";
// import Profiles from "./components/profiles/Profiles";
import Subscription from "./components/subscription/Subscription";
import { logoutUser } from "./actions/authActions";
import "./style/index.scss";
import NewEmployeeModal from "./components/profile/edit_employees/NewEmployeeModal";
import Connections from "./components/client_connections/Connections";
import { PlanUsedUpContext } from "./contexts/PlanUsedUpContext";
import { PlanTypeContext } from "./contexts/PlanTypeContext";
import ManageSubscription from "./components/info/manage_subscription/ManageSubscription";
import { ConnectionsContext } from "./contexts/ConnectionsContext";
import environment from "./utils/environment";
import http from "./utils/http";
import { CurrentClientContext } from "./contexts/CurrentClientContext";
import * as Icon from "react-feather";

const Main = (props) => {
  let { history } = props;

  // const history = useHistory();

  const [planUsedUp, setPlanUsedUp] = useState(false);
  const [planType, setPlanType] = useState(null);
  const [connections, setConnections] = useState([]);
  const [client, setClient] = useState({});
  const [lawFirmInfo, setLawFirmInfo] = useState(null);

  const isLitePlanUsedUp = async () => {
    try {
      let numOfConnections = connections.length;
      console.log("numOfConnections", numOfConnections);

      // if firm has used up the lite plan
      if (numOfConnections > 6) {
        let visibleLiteConnections = connections.filter(function isVisible(
          connection
        ) {
          const createdAt = new Date(connection.createdAt);
          let connectYear = createdAt.getFullYear();

          if (connectYear == 2023) {
            return connection;
          }
        });

        console.log("visibleLiteConnections", visibleLiteConnections);

        if (visibleLiteConnections && visibleLiteConnections.length > 6) {
          console.log("plan is used up ***");
          setPlanUsedUp(true);
        }
      }

      // return result;
    } catch (error) {
      console.log(error);
    }
  };

  const getClientConnections = async () => {
    try {
      const response = await http.get(
        `${environment.resolveApi().rest}/connections/firm/list`
      );

      setConnections(response.data.reverse());
      setClient(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserPlan = async () => {
    try {
      const response = await http.get(
        `${environment.resolveApi().rest}/user/profile`
      );

      setPlanType(response.data.stripe.plan);
    } catch (error) {
      console.log(error);
    }
  };

  const getLawFirmInfo = async () => {
    try {
      const response = await http.get(
        `${environment.resolveApi().rest}/law_firm/info`
      );

      console.log("response", response.data.law_firm);

      if (!response.data.law_firm) {
        console.log("profile doesnt exist");
        history.push("/");
      } else {
        setLawFirmInfo(response.data.law_firm);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    props.logoutUser();
  };

  useEffect(() => {
    getLawFirmInfo();
  }, []);

  useEffect(() => {
    getUserPlan();
    getClientConnections();
  }, [planType]);

  useEffect(() => {
    isLitePlanUsedUp();
  });

  return (
    <div className="Main">
      <PlanUsedUpContext.Provider value={{ planUsedUp, setPlanUsedUp }}>
        <PlanTypeContext.Provider value={{ planType, setPlanType }}>
          <ConnectionsContext.Provider value={{ connections, setConnections }}>
            <CurrentClientContext.Provider value={{ client, setClient }}>
              {lawFirmInfo ? (
                <Navbar />
              ) : (
                <div
                  className="navbar__logout__btn main__logout__btn"
                  onClick={logout}
                >
                  <span className="navbar__txt--default main__logout__btn__txt">
                    Logout
                  </span>

                  <Icon.LogOut
                    className="navbar-icon-default"
                    activeClassName="iconSelected"
                    size={24}
                    color="#FFF"
                  />
                </div>
              )}

              <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute
                  exact
                  path="/connections"
                  component={Connections}
                />
                <PrivateRoute
                  exact
                  path="/create-profile"
                  component={CreateProfile}
                />
                <PrivateRoute
                  exact
                  path="/edit-profile"
                  component={EditProfile}
                />
                {/* <PrivateRoute exact path="/edit-employees" component={Employees} /> */}
                {/* <PrivateRoute exact path="/new-employee" component={NewEmployeeModal} /> */}
                {/* <PrivateRoute exact path="/add-experience" component={Credentials} /> */}
                <PrivateRoute
                  exact
                  path="/edit-hours"
                  component={AddAvailability}
                />
                <PrivateRoute exact path="/account" component={AccountInfo} />
                <PrivateRoute
                  exact
                  path="/subscription"
                  component={ManageSubscription}
                />
                <PrivateRoute exact path="/contact" component={Contact} />

                <PrivateRoute exact path="/employees" component={Employees} />
                {/* <PrivateRoute exact path="/subscription" component={Subscription} /> */}
                <PrivateRoute exact path="*" component={NotFound} />
              </Switch>
            </CurrentClientContext.Provider>
          </ConnectionsContext.Provider>
        </PlanTypeContext.Provider>
      </PlanUsedUpContext.Provider>
    </div>
  );
};

Main.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

export default connect(null, { getCurrentProfile, logoutUser })(Main);
