import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { getCurrentProfile } from '../../actions/profileActions';
import http from '../../utils/http';
import TextAreaField from '../reusable/text_area_field/TextAreaField';
import useForm from '../../hooks/useForm';
import iconProfile from '../layout/navbar/images/profile-icon.svg';
import './socialProfile.scss';
import NavBar from '../reusable/social_navbar/Navbar.jsx'
import { withAlert } from "react-alert";
import environment from '../../utils/environment';


const mapStyles = {
  width: '100%',
  height: '400px',
};


const ProfileHeader = (props) => {
  const { handle } = props.match.params;
  console.log(handle)

  const { google } = props;


  const onSubmit = useCallback(async (inputs) => {
    try {
      const payload = { handle, fullName: inputs.fullName, email: inputs.email, phoneNumber: inputs.phoneNumber, date: inputs.date, service: inputs.service, message: inputs.message };
    
      if ( !payload.fullName || !payload.email || !payload.phoneNumber || !payload.date || !payload.date || !payload.service || !payload.message ) {
        props.alert.error("Missing Input!");
        return console.error('missing message');
    }

      await http.post(`${environment.resolveApi().rest}/mailing/firm/appointment`, payload);
      props.alert.success("Sent Message!");
    } catch (error) {
      console.error('Sending Error!', error);
    }
    viewContactForm(false);
      showSuccessMsg(true);
  });
  
  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);
  const [bookingTimes, viewBookingTimes] = useState(true);
  const [contactForm, viewContactForm] = useState(false);
  const [successMessage, showSuccessMsg] = useState(false);
  const [profile, setProfile] = useState({});
  const [lawFirm, setLawFirm] = useState({});

  const getEmployeeProfile = async () => {
    try {
      const response = await http.get(`${environment.resolveApi().rest}/profile/employee/${handle}`);
      console.log(response.data.profile);
      setProfile(response.data.profile);
      getLawFirmInfo(response.data.profile.law_firm_info);
    } catch (error) {
      console.log(error);
    }
  };

  const getLawFirmInfo = async (lawFirmId) => {
    try {
      const response = await http.get(`${environment.resolveApi().rest}/profile/law_firm/${lawFirmId}`);
      console.log(response.data.law_firm);
      setLawFirm(response.data.law_firm)
    } catch (error) {
      console.log(error);
    }
  };


    useEffect( () => {
      if (profile) {
       getEmployeeProfile();
      }
    }, [])

  

  return (
    <div>

      <NavBar />

      <div className="container">
          <div className="row">
            <div className="col-md-12">
                <div className="SocialProfile" style={{display:'flex', flexFlow: 'column'}}>
                    <div>  
        {/* HEADER */}
                        <div className="social-profile-header-container">
                            { profile.pictureURL ?
                                  <img className="social-profile-view-image" src={profile.pictureURL} alt="" />
                                  :
                                  <div className="rounded-circle profile-view-image default-pic-background">
                                  {/* <img className="default-pic" src={iconProfile} alt="" /> */}
                                </div>
                              }
                              <div className="social-profile-header-text-container">
                                <h1 className="social-profile-name">{`${profile.first_name} ${profile.last_name}`}</h1>
                                <h2 className="social-profile-law-status">{profile.law_title}</h2>
                                <div className="social-profile-website-links-container">
                                  <a className="social-profile-website-link" href="">
                                    <img src={require('./website-icon.svg')} alt="" />
                                  Website
                                  </a>
                                  <a className="social-profile-website-link" href="">
                                    <img src={require('./linkedin.svg')} alt="" />
                                  Linkedin
                                  </a>
                                </div>
                                <img style={{maxWidth: '260px', marginTop: '2.5em'}} src={lawFirm.companyLogo}/>
                              </div>
                      
    { bookingTimes
        && (
        <div className="social-profile-contact-container">
          <div className="social-profile-contact-title-container">
            <img src={require('./availability.svg')} alt="" />
            <strong>CONNECT WITH {profile.law_title}</strong>
          </div>
          { profile.availability ?
          <div>
          <strong className="social-profile-contact-hours-text">Hours</strong>
          <div className="social-profile-contact-hours-container">
            <div className="social-profile-contact-hours-text-container">
              <span>Mon.</span>
              <hr />
              <span>{`${profile.availability.monday.from} - ${profile.availability.monday.to}`}</span>
            </div>
            <div className="social-profile-contact-hours-text-container">
              <span>Tue.</span>
              <hr />
              <span>{`${profile.availability.tuesday.from} - ${profile.availability.tuesday.to}`}</span>
            </div>
            <div className="social-profile-contact-hours-text-container">
              <span>Wed.</span>
              <hr />
              <span>{`${profile.availability.wednesday.from} - ${profile.availability.wednesday.to}`}</span>
            </div>
            <div className="social-profile-contact-hours-text-container">
              <span>Thu.</span>
              <hr />
              <span>{`${profile.availability.thursday.from} - ${profile.availability.thursday.to}`}</span>
            </div>
            <div className="social-profile-contact-hours-text-container">
              <span>Fri.</span>
              <hr />
              <span>{`${profile.availability.friday.from} - ${profile.availability.friday.to}`}</span>
            </div>
            <div className="social-profile-contact-hours-text-container">
              <span>Sat.</span>
              <hr />
              <span>{`${profile.availability.saturday.from} - ${profile.availability.saturday.to}`}</span>
            </div>
            <div className="social-profile-contact-hours-text-container">
              <span>Sun.</span>
              <hr />
              <span>{`${profile.availability.sunday.from} - ${profile.availability.sunday.to}`}</span>
            </div>
          </div>
          </div>
          :
          <p>
            Availability timing not currently set
          </p>
    }
          <button type="submit" onClick={() => { viewBookingTimes(false); viewContactForm(true); }} className="social-profile-contact-button">CONNECT</button>

          </div>
     
        )}

                {/*  */}
    { contactForm
        && (
        <div className="social-profile-contact-msg-container">
          <div className="social-profile-contact-title-container">
            <img src={require('./availability.svg')} alt="" />
            <strong>CONNECT WITH {profile.law_title}</strong>
          </div>
          {/* <strong className="social-profile-contact-hours-text">Hours</strong> */}
          <form onSubmit={handleSubmit}>
            <div className="social-profile-contact-hours-container" style={{ marginBottom: '45px' }}>
            <div className="input-container">
              <span className="lw-portal-text-input-title">Full Name</span>
              <input
                className="lw-portal-text-input text-field-name"
                placeholder=""
                name="fullName"
                type="text"
                value={inputs.fullName}
                onChange={handleInputChange}
                autoComplete="off"
              />
            </div>
            <div className="input-container">
              <span className="lw-portal-text-input-title">Email</span>
              <input
                className="lw-portal-text-input text-field-email"
                placeholder=""
                name="email"
                type="email"
                value={inputs.email}
                onChange={handleInputChange}
                autoComplete="off"
              />
          </div>
              <div className="sp-booking-date-container">
              <div className="input-container">
                <span className="lw-portal-text-input-title">Phone  </span>
                <input
                  className="lw-portal-text-input text-field-time"
                  placeholder=""
                  type="text"
                  name="phoneNumber"
                  value={inputs.phoneNumber}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
              <div className="input-container">
                <span className="lw-portal-text-input-title">Date</span>
                <input
                  className="lw-portal-text-input text-field-date"
                  placeholder=""
                  name="date"
                  type="date"
                  value={inputs.date}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>
                <div className="input-container">
                <span className="lw-portal-text-input-title">Service</span>
                  <input
                    className=" service-input"
                    placeholder=""
                    name="service"
                    type="text"
                    value={inputs.service}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </div>
              </div>

              <div>
                <TextAreaField
                  className="modal-text-area-field text-field-msg"
                  title="Message"
                  placeholder=""
                  name="message"
                  type="text"
                  value={inputs.message}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>

            </div>
            <button type="submit" className="social-profile-contact-button social-profile-contact-book-button">CONNECT</button>
          </form>
          <p className="consent-txt">By submitting your contact information, you agree to our Terms of Service and Privacy Policy and consent to having CounselConnect contact you by text message and email.</p>
        </div>
        )}


        { successMessage
        && (
        <div className="social-profile-contact-container social-profile-contact-success-container">
          <div className="sp-contact-msg-container">
            <img src={require('./success.svg')} alt="" />
            <span className="sp-contact-success-msg-txt">Message Sent !</span>
            <span className="sp-contact-info-msg-txt">We have forwarded your connection request to {profile.first_name}, they shall be contacting you very soon.</span>
          </div>
        </div>
        )}

  </div>
</div>
           {/*  */}

<div className="social-profile-content-container">
                  <div className="social-profile-content-1">

                  <div className="social-profile-content-1-title-container">
                    <span>What you need to know</span>
                    <div>
                      <img src={require('./oval.svg')} alt="" />
                      <hr />
                    </div>
                  </div>

                  <div className="social-profile-content-1-body">
                    <div className="social-profile-content-1-body-subtitle">
                      <span>Languages spoken</span>
                      <img src={require('./languages.svg')} alt="" />
                    </div>
                    <div>
                      <p className="social-profile-sub-labels">{profile.languages}</p>
                    </div>
                  </div>

                  <div className="social-profile-content-1-body">
                    <div className="social-profile-content-1-body-subtitle">
                      <span className="social-profile-sub-labels">Areas of service</span>
                      <img src={require('./tool.svg')} alt="" />
                    </div>
                    { profile.law_services ?
                      <div style={{ lineHeight: '1.2' }}>
                          {profile.law_services.map(service => {
                            return (
                              <p className="social-profile-sub-labels">{service}</p>
                            );
                          })}
                      </div>
                      :
                      <div></div>
                    } 
            </div>       
          </div>                
        </div>

{/* BIO */}
{ profile.bio ?
                  <div className="social-profile-content-1">

                    <div className="social-profile-content-1-title-container">
                      <span>{`${profile.first_name} ${profile.last_name}`}</span>
                      <div>
                        <img src={require('./oval.svg')} alt="" />
                        <hr />
                      </div>
                    </div>

                    <div className="social-profile-content-1-body">
                      <div className="social-profile-content-bio-container">
                      
                        { profile.pictureURL ?
                      <img className="social-profile-view-image" src={profile.pictureURL} alt="" />
                          :
                      <div></div>
                      }
                        <p>{profile.bio}</p>
                      </div>
                    </div>

                  </div>
                  :
                
                <div></div>
          }
      

          
{/* LOCATION */}
 { lawFirm.lat && lawFirm.lng ?
        <div className="social-profile-content-1-office">

          <div className="social-profile-content-1-title-container">
            <span>Office</span>
            <div>
              <img src={require('./oval.svg')} alt="" />
              <hr />
            </div>

            <span>{lawFirm.address}</span>
            <div className="address-sub-details">
              <span>
                {lawFirm.city}
,
              </span>
              <span style={{ marginLeft: '10px' }}>
                {' '}
ON
                {' '}
                {lawFirm.postalcode }
              </span>
            </div>
          </div>
       
          <div className="map-container">
            <Map
              google={google}
              zoom={13}
              style={mapStyles}
              initialCenter={{ lat: lawFirm.lat, lng: lawFirm.lng }}
            >
              <Marker position={{ lat: lawFirm.lat, lng: lawFirm.lng }} />
            </Map>
          </div>

         
          <div className="social-profile-content-1-body">
            <div className="social-profile-content-1-body-subtitle" />
            <div />
          </div>

        </div>
 :
 <div className="social-profile-content-1-office" style={{height: "10%"}}>

 <div className="social-profile-content-1-title-container">
   <span>Office</span>
   <div>
     <img src={require('./oval.svg')} alt="" />
     <hr />
   </div>

   <span>{lawFirm.address}</span>
   <div className="address-sub-details">
     <span>
       {lawFirm.city}
,
     </span>
     <span style={{ marginLeft: '10px' }}>
       {' '}
ON
       {' '}
       {lawFirm.postalcode}
     </span>
   </div>
 </div>

</div>
}  




        


                </div>
            </div>
          </div>
      </div>

    </div>
  );
};

export default (GoogleApiWrapper({ apiKey: ('AIzaSyBydKebS6oGDjm95HKdQi2YzP6wLA4iYaI') })(withAlert()(ProfileHeader)));
