import React, { useEffect, useState } from "react";

import { withAlert } from "react-alert";
import "./Subscription.scss";

import * as Icon from "react-feather";

import CC__PremiumBtn from "./cc_premium_btn/CC_PremiumBtn";
import CC__LiteBtn from "./cc_lite_btn/CC_LiteBtn";
// import { useHistory } from "react-router";

const Subscription = (props) => {
  const { history } = props;

  const [onHasLite, setOnHasLite] = useState(false);
  const [onHasPremium, setOnHasPremium] = useState(false);

  // const history = useHistory();

  const RedirectLink = (url) => {
    window.location.replace(url);
  };

  const hasReachedCClite__Limit = async () => {};

  useEffect(() => {
    hasReachedCClite__Limit();
  }, []);

  // const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);

  return (
    <div className="">
      <div className="Subscription__Outer">
        <div className="Subscription">
          <div className="noPremiumLogin__content subscription__noPremiumLogin__content">
            {!onHasLite && !onHasPremium && (
              <>
                <span className="noPremiumLogin__content__title noPremiumLogin__content__title--dashboard">
                  Oh, no it looks like you’re not a CounselConnect Premium
                  member.
                </span>
                <span className="noPremiumLogin__content__subtitle noPremiumLogin__content__subtitle--dashboard">
                  Signup for CounselConnect Premium and gain access to your
                  client connections!
                </span>
              </>
            )}

            {onHasPremium && (
              <span
                className="noPremiumLogin__content__title noPremiumLogin__content__title--dashboard subscription__currentPlanTxt"
                style={{ marginTop: 20 }}
              >
                Current Plan: CC PREMIUM
              </span>
            )}

            {onHasLite && (
              <span
                className="noPremiumLogin__content__title noPremiumLogin__content__title--dashboard subscription__currentPlanTxt"
                style={{ marginTop: 20 }}
              >
                Current Plan: CC LITE
              </span>
            )}

            <div className="noPremiumLogin__content__pricing subscription__noPremiumLogin__content__pricing--free">
              <div className="noPremiumLogin__content__pricing__header">
                <span className="noPremiumLogin__content__pricing__header__txt ">
                  CC Lite (14 days free)
                </span>
                <div className="flex-column">
                  <span className="noPremiumLogin__content__pricing__header__price ">
                    $25
                  </span>
                  <small className="noPremiumLogin__content__pricing__header__subtxt ">
                    monthly
                  </small>
                </div>
              </div>

              <span className="noPremiumLogin__content__pricing__content__headerTxt ">
                Plan includes:
              </span>

              <div className=" noPremiumLogin__content__pricing__content--dashboard">
                <div className="noPremiumLogin__content__pricing__content__item">
                  <Icon.Check color="#FFF" className="featherIcon__check" />
                  <span className="noPremiumLogin__content__pricing__content__txt ">
                    5 client connections (pr year)
                  </span>
                </div>
                <div className="noPremiumLogin__content__pricing__content__item">
                  <Icon.Check color="#FFF" className="featherIcon__check" />
                  <span className="noPremiumLogin__content__pricing__content__txt ">
                    Full Detailed Profile
                  </span>
                </div>
                <div className="noPremiumLogin__content__pricing__content__item">
                  <Icon.Check color="#FFF" className="featherIcon__check" />
                  <span className="noPremiumLogin__content__pricing__content__txt ">
                    Unlimited Pages Views
                  </span>
                </div>
              </div>
              <CC__LiteBtn onHasLite={onHasLite} setOnHasLite={setOnHasLite} />
            </div>
          </div>

          <div className="noPremiumLogin__content__pricing subscription__noPremiumLogin__content__pricing--premium">
            <div className="noPremiumLogin__content__pricing__header">
              <span className="noPremiumLogin__content__pricing__header__txt">
                CC Premium (14 days free)
              </span>
              <div className="flex-column">
                <span className="noPremiumLogin__content__pricing__header__price">
                  $59
                </span>
                <small className="noPremiumLogin__content__pricing__header__subtxt">
                  monthly
                </small>
              </div>
            </div>

            <span className="noPremiumLogin__content__pricing__content__headerTxt">
              Plan includes:
            </span>

            <div className="noPremiumLogin__content__pricing__content--dashboard">
              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt">
                  Unlimited client connections
                </span>
              </div>
              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt">
                  Search result priority over free users
                </span>
              </div>

              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt">
                  Gold styled profile cards
                </span>
              </div>

              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt">
                  Full Detailed Profile
                </span>
              </div>

              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt ">
                  Unlimited Pages Views
                </span>
              </div>

              <CC__PremiumBtn
                onHasPremium={onHasLite}
                setOnHasPremium={setOnHasPremium}
              />
            </div>
          </div>

          <div
            className="noPremiumLogin__homeBtn"
            onClick={() => {
              history.push("/");
            }}
          >
            Home {">"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAlert()(Subscription);
