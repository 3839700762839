import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import http from '../../utils/http';
import './profileitem.scss';
import trustedIcon from '../client_search/reliability.svg';
import environment from '../../utils/environment';

const ProfileItem = (props) => {
 
    const { profile } = props;
    const handle = profile.handle;
    const lawTitle = `${profile.status}`
   
    const [lawFirmInfo, setFirmInfo] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
 
    const getUserInfoFromHandle = async () => {
      try {
        const response = await http.get(`${environment.resolveApi().rest}/profile/${handle}/info`);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
      } catch (error) {
        console.log(error);
      }
    };

    // const fetchLawFirmInfo = async (lawFirmName) => {
    //   try {
    //     const response = await http.post(`${environment.resolveApi().rest}/lawFirms/info`, {lawFirmName});
    //     setFirmInfo(response.data.lawFirmInfo);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    useEffect( () => {
      getUserInfoFromHandle();
      // fetchLawFirmInfo(profile.lawFirmName);
    }, [handle]);

   
    return (
      <div className="profile-card-p">
        <div className="card-p">
      
          <div className="profile-card-image">
            <div>
              <span className="cc-trusted"><img style={{width: '20px', marginBottom: '5px', marginRight: '8px'}} src={trustedIcon} alt="" />COUNSELCONNECT TRUSTED</span>
            </div>
            { profile.pictureURL ?
             <img className="rounded-circle profile-card-img" src={`${profile.pictureURL}`} alt="" />
            :
            <div>
          <div className="rounded-circle default-pic-background" />
           {/* <img style={{maxWidth: '150px', marginTop: '2.5em'}} src={lawFirmInfo.pictureURL}/> */}
           </div>
        }
          </div>

          <div className="card-info">
            <div className="profile-card-title">
              <h3>{`${firstName} ${lastName}`}</h3>
              <p>{lawTitle}</p>
              <span className="profile-fields">{`${profile.field}, `}</span>
              <hr />
            </div>

            <div className="profile-card-info">
              <h4>
                <i className="fas fa-language" style={{ fontSize: '1.3em', marginRight: '.5em', color: '#D0021B' }} />
                Languages
              </h4>
              <p>{profile.languages}</p>
            
            </div>

          
            
            <div className="profile-card-button">
              <Link to={{pathname: `/profile/${handle}`}}>
                <button>
                View full profile
                </button>
              </Link>
            </div>
          </div>
         
        </div>
         
      </div>
    );
  
}


export default ProfileItem;
