import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// import { getCurrentProfile } from '../../actions/profileActions';
import Spinner from "../reusable/spinner/Spinner";
import http from "../../utils/http";
import environment from "../../utils/environment";
// import "./style/dashboard.scss";
import iconProfile from "../layout/navbar/images/profile-icon.svg";

import { logoutUser } from "../../actions/authActions";
import * as Icon from "react-feather";
import "./Connections.scss";
import StatsSection from "./stats_section/StatsSection";
import ClientList from "./client_list/ClientList";
import ClientInfo from "./client_info/ClientInfo";
import { PlanSelectedContext } from "../../contexts/PlanSelectedContext";
import { PlanUsedUpContext } from "../../contexts/PlanUsedUpContext";
import { PlanTypeContext } from "../../contexts/PlanTypeContext";
import { useHistory } from "react-router";
import { ConnectionsContext } from "../../contexts/ConnectionsContext";
import { CurrentClientContext } from "../../contexts/CurrentClientContext";
import Tooltip from "react-tooltip-lite";

import { CopyToClipboard } from "react-copy-to-clipboard";

const Connections = (props) => {
  const [firmPlan, setFirmPlan] = useState("free");
  const [isValid, setIsValid] = useState(false);
  const { planUsedUp, setPlanUsedUp } = useContext(PlanUsedUpContext);
  const { connections, setConnections } = useContext(ConnectionsContext);
  const { client, setClient } = useContext(CurrentClientContext);
  const [oldestConnection__index, setOldestConnection__index] = useState(null);
  const [shareProfileLink, setShareProfile] = useState("Copy Profile Link");

  return (
    <div className="Dashboard Connections">
      {/* <StatsSection /> */}
      {/* 
      <div className="connections__content-container">
        <ClientList
          setClient={setClient}
          clientSelected={client}
          firmPlan={firmPlan}
          connections={connections}
          isValid={isValid}
          oldestConnection__index={oldestConnection__index}
          setOldestConnection__index={setOldestConnection__index}
        />

        <ClientInfo
          client={client}
          planUsedUp={planUsedUp}
          oldestConnection__index={oldestConnection__index}
        />
      </div> */}

      {connections.length > 0 ? (
        <div className="connections__content-container">
          <ClientList
            setClient={setClient}
            clientSelected={client}
            firmPlan={firmPlan}
            connections={connections}
            isValid={isValid}
            oldestConnection__index={oldestConnection__index}
            setOldestConnection__index={setOldestConnection__index}
          />

          <ClientInfo
            client={client}
            planUsedUp={planUsedUp}
            oldestConnection__index={oldestConnection__index}
          />
        </div>
      ) : (
        <>
          <span className="connections__none__title">Connections</span>
          <div className="connections__none">
            <span className="connections__none__txt">
              You currently have no client connections, we will notify you
              through email for any connections!
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Connections;
