import React, { useCallback, useState } from "react";
import http from "../../utils/http";
import useForm from "../../hooks/useForm";
import SelectList from "../reusable/select_list/SelectList";
import TextField from "../reusable/text_field/TextField";
import "./style/register.scss";
import logo from "./images/logo.svg";
import { withAlert } from "react-alert";
import environment from "../../utils/environment";

// TODO: errors

// const initialState = {
//   firstName: '',
//   lastName: '',
//   email: '',
//   phoneNumber: '',
//   lawTitle: '',
//   lawField: '',
// };

const statusOptions = [
  { label: "* Select Law Title", value: 0 },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Paralegal", value: "Paralegal" },
];

// Select field options for field of work
const paralegalCategories = [
  { label: "Traffic Tickets", value: "Traffic Tickets" },
  { label: "Minor Offences", value: "Minor Offences" },
  { label: "Landlord or Tenant", value: "Landlord or Tenant" },
  { label: "Employment Law", value: "Employment Law" },
  { label: "Small Claims", value: "Small Claims" },
  { label: "Immigration Law", value: "Immigration Law" },
];

const lawyerCategories = [
  { label: "Criminal Law", value: "Criminal Law" },
  { label: "Commercial Law", value: "Commercial Law" },
  { label: "Civil Law", value: "Civil Law" },
  { label: "Employment Law", value: "Employment Law" },
  { label: "Family Law", value: "Family Law" },
  { label: "Immigration Law", value: "Immigration Law" },
  { label: "Real Estate", value: "Real Estate" },
];

const SignUpRequest = (props) => {
  const { history } = props;

  const onSubmit = useCallback(async (inputs) => {
    try {
      const payload = {
        lawFirmName: inputs.lawFirmName,
        firmOwner: inputs.firmOwner,
        email: inputs.email,
        phoneNumber: inputs.phoneNumber,
        city: inputs.city,
      };

      if (
        !payload.lawFirmName ||
        !payload.firmOwner ||
        !payload.email ||
        !payload.phoneNumber ||
        !payload.city
      ) {
        props.alert.error("Missing Input!");
        return console.error("missing message");
      }
      await http.post(
        `${environment.resolveApi().rest}/mailing/lawFirm/verify`,
        payload
      );
      history.push("/signup/request/success");
    } catch (error) {
      console.log(error);
    }
  });

  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);
  const [fields, setLawFields] = useState([]);

  const handleChange = (selectedOption) => {
    setLawFields(selectedOption);
    groupFeilds(selectedOption);
  };

  const groupFeilds = (selectedOption) => {
    let arr = [];

    if (selectedOption != null) {
      for (let i = 0; i < selectedOption.length; i++) {
        arr.push(selectedOption[i].value);
      }
    }
    setLawFields(arr);
  };

  return (
    <div className="SignUp">
      <div className="signup-header">
        <img className="side-navbar-home-icon" src={logo} alt="" />
        <h1 className="signup-title">
          Create a CounselConnect Account for free!
        </h1>
        <p>
          Provide us with some information, and we will begin our verification
          process
        </p>
      </div>

      <form onSubmit={handleSubmit} className="signup-form-container">
        <div style={{ display: "flex", flexFlow: "row", marginBottom: "10px" }}>
          <TextField
            className="signup-input first-name-input"
            title="Name of Law Firm"
            placeholder=""
            name="lawFirmName"
            type="text"
            value={inputs.lawFirmName}
            onChange={handleInputChange}
            //   error={errors.name}
            autoComplete="off"
          />
          <div style={{ marginLeft: "20px" }}>
            <div className="input-container">
              <TextField
                className="signup-input last-name-input"
                title="Firm Owner"
                placeholder=""
                name="firmOwner"
                type="text"
                value={inputs.firmOwner}
                onChange={handleInputChange}
                // error={errors.email}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <TextField
          className="signup-input email-input"
          title="Email"
          placeholder=""
          name="email"
          type="email"
          value={inputs.email}
          onChange={handleInputChange}
          // error={errors.password}
          autoComplete="off"
        />

        <div
          style={{ display: "flex", flexFlow: "row", marginBottom: "20px" }}
          className="input-container"
        >
          <TextField
            className="signup-input first-name-input phonenumber-input"
            title="Phone Number"
            placeholder=""
            name="phoneNumber"
            type="text"
            value={inputs.phoneNumber}
            onChange={handleInputChange}
            // error={errors.password}
            autoComplete="off"
          />
          <div style={{ marginLeft: "20px" }}>
            <TextField
              className="signup-input first-name-input phonenumber-input"
              title="City"
              placeholder=""
              name="city"
              type="text"
              value={inputs.city}
              onChange={handleInputChange}
              // error={errors.password}
              autoComplete="off"
            />
          </div>
        </div>

        <button type="submit" className="signup-button">
          GET VERIFIED
        </button>
      </form>
      <p className="copyright text-muted">
        Copyright &copy; 2023 by CounselConnect
      </p>
    </div>
  );
};

export default withAlert()(SignUpRequest);
