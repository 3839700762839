import React, { useState } from 'react';
import './location.scss';
import { withAlert } from "react-alert";

const lawyerFields = [
  { label: 'Criminal Law', value: 'Criminal Law' },
  { label: 'Commercial Law', value: 'Commercial Law' },
  { label: 'Civil Law', value: 'Civil Law' },
  { label: 'Employment Law', value: 'Employment Law' },
  { label: 'Family Law', value: 'Family Law' },
  { label: 'Immigration Law', value: 'Immigration Law' },
  { label: 'Real Estate', value: 'Real Estate' },
];

const arr = [];

const Lawyer = (props) => {
  const { city, status } = props.location.state;
  // console.log(city, status);

  const [lawFields, setLawFields] = useState(arr);

  const onSubmitFields = () => {
    if(arr.length === 0) {
      props.alert.error("Select a category!");
    } else {
      setLawFields(arr);
      props.history.push({pathname: `/search_by_gender`, state: { city: city, status: status, field: lawFields }});
    }
  };

  const btnClick = (event) => {
    if (event) event.preventDefault();
    // const index = arr.indexOf(event.currentTarget.value);
    arr.push(event.currentTarget.value);
    onSubmitFields();
    // if (index > -1) {
    //   arr.splice(index, 1);
    // } else {
    //   arr.push(event.currentTarget.value);
    // }
  };

  return(
  <div className="Lawyer-Search">
    <h1 className="location-search-title">Lawyer Categories</h1>
    <p className="location-search-subtitle">Select the law field that best fits your needs.</p>
    <div className="law-field-options">
      {lawyerFields.map((field, index) => (
        <button type="submit" className="status-search-lawyer-button" onClick={btnClick} key={index.id} value={field.value}>{field.value}</button>
      ))}
    </div>
  </div>
);
};

export default withAlert()(Lawyer);
