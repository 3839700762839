import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PlanSelectedContext } from "../../contexts/PlanSelectedContext";
import CC__LiteBtn from "../subscription/cc_lite_btn/CC_LiteBtn";
import CC__PremiumBtn from "../subscription/cc_premium_btn/CC_PremiumBtn";
import http from "../../utils/http";
import environment from "../../utils/environment";
import { PlanTypeContext } from "../../contexts/PlanTypeContext";
import { PlanUsedUpContext } from "../../contexts/PlanUsedUpContext";
import ccHeart from "./images/cc-free-heart.svg";

const planContents = [
  {
    header: "Free",
    price: 0,
    features: [
      "1 client connection",
      "No social links",
      "Limited to 200 profile page views",
    ],
    buttonLabel: "Sign up for Free",
    outline: true,
  },

  {
    header: "Lite",
    price: 25,
    features: [
      "5 client connections (pr year)",
      "Social Links",
      "Unlimited Pages Views",
    ],
    buttonLabel: "Sign up for Lite",
    outline: false,
  },
  {
    header: "Premium",
    price: 59,
    features: [
      "Unlimited client connections",
      "Search result priority over free & lite users",
      "Gold styled profile cards",
      "Everything in Lite Plan",
    ],
    buttonLabel: "Sign up for Premium",
    outline: false,
  },
];

const Plan = (props) => {
  let { reccurType, user, noFree, flow, setPageLoading, setResetComponent } =
    props;
  const PLAN_SELECTED_CONTEXT = useContext(PlanSelectedContext);
  const history = useHistory();
  const [onHasLite, setOnHasLite] = useState(false);
  const [onHasPremium, setOnHasPremium] = useState(false);
  const { planType, setPlanType } = useContext(PlanTypeContext);
  const { planUsedUp } = useContext(PlanUsedUpContext);

  const isLitePlanUsedUp = async () => {
    try {
    } catch (error) {
      console.log(error);
    }
  };

  const getAnnualPrice = (monthlyPrice) => {
    if (monthlyPrice == "59") {
      monthlyPrice++;
    }

    let yearly = monthlyPrice * 12;
    // 15% off
    let discount = yearly * 0.15;

    yearly -= discount;

    return Math.round(yearly);
  };

  const downgradeToFree = async () => {
    try {
      setPageLoading(true);
      const response = await http.post(
        `${environment.resolveApi().rest}/payments/downgrade-to-free`
      );

      console.log("downgradeToFree - respoonse", response);

      setResetComponent(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(
      "noFree",
      noFree,
      "planUsedUp",
      planUsedUp,
      "planTddwdwdwype",
      planType
    );
  });

  if (flow == "manage-plan" || flow == "upgrade-plan") {
    return (
      <div className="card mb-4 shadow-sm subPlan__card">
        <div
          className={`${props.header == "Free" && "card-header"}  ${
            props.header == "Lite" && "card-header card-header--ccLite"
          } ${
            props.header == "Premium" && "card-header card-header--ccPremium"
          }`}
        >
          <h4 className="my-0 font-weight-normal">{props.header}</h4>
        </div>

        {/* PRICE */}
        <div className="card-body">
          <h1 className="card-title pricing-card-title">
            {reccurType == "Annual"
              ? `$${getAnnualPrice(props.price)}`
              : `$${props.price}`}

            {reccurType == "Monthly" ? (
              <small className="text-muted">/ mo</small>
            ) : (
              <small className="text-muted">/ yr</small>
            )}
          </h1>
          {props.header == "Premium" && !noFree && (
            <span className="subscription__freeTrial__txt">
              14 days Free Trial
            </span>
          )}

          {props.header == "Lite" && !planUsedUp && (
            <span className="subscription__freeTrial__txt">
              14 days Free Trial
            </span>
          )}

          {props.header == "Lite" && planUsedUp && (
            <div className="subPlan__ccLite__usedUp--container">
              <span className="subPlan__ccLite__usedUpTxt">
                Current Plan: You have used up 5/5 connections for this year
              </span>
            </div>
          )}

          {props.header == "Lite" &&
            noFree &&
            !planUsedUp &&
            planType == "lite" && (
              <span className="subscription__freeTrial__txt">
                14 days Free Trial
              </span>
            )}

          {props.header == "Premium" && noFree && (
            <span className="subscription__freeTrial__txt">
              14 days Free Trial
            </span>
          )}
          <ul className="list-unstyled mt-3 mb-4">
            {props.features.map((feature, i) => (
              <li key={i} className="subPlan__featureTxt">
                {feature}
              </li>
            ))}
          </ul>
          {props.header == "Free" &&
            (planType == "lite" || planType == "premium") && (
              <div className="">
                <button
                  type="button"
                  className="navbar__links__ccFree__btn"
                  onClick={props.downgradeToFree}
                >
                  Downgrade to Free
                </button>
              </div>
            )}

          {props.header == "Free" && planType == "free" && (
            <div className="navbar__links__ccPremium navbar__links__ccPremium--manage">
              <div className="navbar__links__ccPremiumTxtWrap">
                <img src={ccHeart} className="navbar__links__ccPremium__icon" />
                <span className="navbar__links__ccPremium__label">
                  CURRENT PLAN
                </span>
              </div>
            </div>
          )}

          {props.header == "Lite" && !planUsedUp && (
            <CC__LiteBtn
              onHasLite={onHasLite}
              setOnHasLite={setOnHasLite}
              reccurType={reccurType}
              planType={planType}
              flow={flow}
            />
          )}

          {/* {props.header == "Lite" && planUsedUp && (
            <span>Can upgrade after April 2024</span>
          )} */}

          {props.header == "Premium" && (
            <CC__PremiumBtn
              onHasPremium={onHasPremium}
              setOnHasPremium={setOnHasPremium}
              planType={reccurType}
              flow={flow}
            />
          )}
        </div>
      </div>
    );
  }

  if ("new-profile") {
    return (
      <div className="card mb-4 shadow-sm subPlan__card">
        <div
          className={`${props.header == "Free" && "card-header"}  ${
            props.header == "Lite" && "card-header card-header--ccLite"
          } ${
            props.header == "Premium" && "card-header card-header--ccPremium"
          }`}
        >
          <h4 className="my-0 font-weight-normal">{props.header}</h4>
        </div>
        <div className="card-body">
          <h1 className="card-title pricing-card-title">
            {reccurType == "Annual"
              ? `$${getAnnualPrice(props.price)}`
              : `$${props.price}`}

            {reccurType == "Monthly" ? (
              <small className="text-muted">/ mo</small>
            ) : (
              <small className="text-muted">/ yr</small>
            )}
          </h1>
          {(props.header == "Lite" || props.header == "Premium") && !noFree && (
            <span className="subscription__freeTrial__txt">
              14 days Free Trial
            </span>
          )}
          {props.header == "Lite" &&
            noFree &&
            planUsedUp &&
            planType == "lite" && (
              <div className="subPlan__ccLite__usedUp--container">
                <span className="subPlan__ccLite__usedUpTxt">
                  Current Plan: You have used up 5/5 connections for this year
                </span>
              </div>
            )}
          {props.header == "Lite" &&
            noFree &&
            !planUsedUp &&
            planType == "lite" && (
              <span className="subscription__freeTrial__txt">
                14 days Free Trial
              </span>
            )}
          {props.header == "Lite" &&
            noFree &&
            planUsedUp &&
            planType == "free" && (
              <span className="subscription__freeTrial__txt">
                14 days Free Trial
              </span>
            )}
          {props.header == "Premium" && noFree && (
            <span className="subscription__freeTrial__txt">
              14 days Free Trial
            </span>
          )}
          <ul className="list-unstyled mt-3 mb-4">
            {props.features.map((feature, i) => (
              <li key={i} className="subPlan__featureTxt">
                {feature}
              </li>
            ))}
          </ul>
          {props.header == "Free" && (
            <div className="">
              <button
                type="button"
                className="navbar__links__ccFree__btn"
                onClick={() => {
                  setPlanType("free");
                  PLAN_SELECTED_CONTEXT.setPlanSelected(true);
                }}
              >
                Continue with Free
              </button>
            </div>
          )}
          {props.header == "Lite" && !noFree && (
            <CC__LiteBtn
              onHasLite={onHasLite}
              setOnHasLite={setOnHasLite}
              planType={reccurType}
              flow={flow}
            />
          )}
          {props.header == "Lite" &&
            noFree &&
            planUsedUp &&
            planType == "free" && (
              <CC__LiteBtn
                onHasLite={onHasLite}
                setOnHasLite={setOnHasLite}
                planType={reccurType}
                flow={flow}
              />
            )}
          {props.header == "Lite" &&
            noFree &&
            !planUsedUp &&
            planType !== "lite" && (
              <CC__LiteBtn
                onHasLite={onHasLite}
                setOnHasLite={setOnHasLite}
                planType={reccurType}
                flow={flow}
              />
            )}
          {props.header == "Premium" && (
            <CC__PremiumBtn
              onHasPremium={onHasPremium}
              setOnHasPremium={setOnHasPremium}
              planType={reccurType}
              flow={flow}
            />
          )}
        </div>
      </div>
    );
  }
};

const SubscriptionPlans = (props) => {
  let { noFree, setResetComponent, setPlanType, setPageLoading, pageLoading } =
    props;

  // let [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log("user sub plan", user.data.stripe.plan);
  });

  const plans = planContents.map((obj, i) => {
    if (noFree) {
      if (obj.header !== "Free") {
        return (
          <Plan
            key={obj.header}
            header={obj.header}
            price={obj.price}
            features={obj.features}
            buttonLabel={obj.buttonLabel}
            outline={obj.outline}
            {...props}
          />
        );
      }
    } else {
      return (
        <Plan
          key={obj.header}
          header={obj.header}
          price={obj.price}
          features={obj.features}
          buttonLabel={obj.buttonLabel}
          outline={obj.outline}
          setPageLoading={setPageLoading}
          {...props}
        />
      );
    }
  });

  return <div className="card-deck mb-3 text-center">{plans}</div>;
};

export default SubscriptionPlans;
