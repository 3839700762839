import React, { useEffect, useCallback, useState } from "react";
import NewEmployeeModal from "./NewEmployeeModal";
import UpdateEmployeeModal from "./UpdateEmployeeModal";
import DeleteEmployeeModal from "./DeleteEmployeeModal";
import MicroModal from "micromodal";
import http from "../../../utils/http";
import SubNavbar from "../../reusable/sub_navbar/SubNavbar";
import { withAlert } from "react-alert";
import "./Employees.scss";
import environment from "../../../utils/environment";
import logo from "../../law_profile/0.jpg";
import spinner from "./spinner_2.gif";
import * as Icon from "react-feather";

const Employees = (props) => {
  const onNewEmployee = useCallback(() => MicroModal.show("new-employee"), []);
  const onEmployeeUpdate = useCallback(
    () => MicroModal.show("update-employee"),
    []
  );
  const onDeleteEmployee = useCallback(
    () => MicroModal.show("delete-employee"),
    []
  );

  const getEmployeeProfiles = async () => {
    try {
      const response = await http.get(
        `${environment.resolveApi().rest}/law_firm/profiles`
      );
      setEmpList(response.data.profiles);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // const deleteEmployee = async (employee_handle) => {
  //   try {
  //     await http.post(`${environment.resolveApi().rest}/employee_profile/`, { employee_handle });
  //     setLoading(false);
  //     props.alert.success("Employee Deleted!");
  //     getEmployeeProfiles();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const getEmployeeData = async (handle) => {
    try {
      setLoading(true);
      const response = await http.get(
        `${environment.resolveApi().rest}/employee_profile/${handle}`
      );
      console.log(response.data.profile);
      setEmpData(response.data.profile);
      onEmployeeUpdate();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // const btnClick = (event) => {
  //   setLoading(true);
  //   if (event) event.preventDefault();
  //   // pass employee handle from the button event click
  //   deleteEmployee(event.currentTarget.value);
  // };

  const btnClick2 = (event) => {
    if (event) event.preventDefault();
    getEmployeeData(event.currentTarget.value);
  };

  const [empList, setEmpList] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [empData, setEmpData] = useState({});
  const [empDeleteData, setEmpDeleteData] = useState({});
  // const [empHandle, setEmpHandle] = useState(false);

  useEffect(() => {
    setLoading(true);
    getEmployeeProfiles();
  }, []);

  return (
    <div className="Employees">
      {/* <SubNavbar title="Law Practitioners" config="profile" /> */}

      {loading ? (
        <div className="law-firm-portal-pg-spinner-container">
          <h2 className="modal-spinner-title">Loading Law Practitioners</h2>
          <img
            className="modal-loading-spinner"
            src={spinner}
            alt="Loading..."
          />
        </div>
      ) : (
        <div className="edit-employees-pg-content-container">
          {empList.length > 0 ? (
            <>
              <div className="edit-employees-pg-header-wrapper">
                <span className="connections__none__title">Practitioners</span>
                <div className="connections__none__btn" onClick={onNewEmployee}>
                  <span className="connections__none__btn__txt">
                    Add Lawyer
                  </span>
                  <Icon.UserPlus size={21} color={"#3B1212"} />
                </div>
              </div>

              <section className="edit-employees-pg-employees-wrapper">
                {/* EMPLOYEE CARD BODY */}

                {empList.map((profile, index) => (
                  <div key={index} className="edit-employees-pg-employees-card">
                    <div className="edit-employees-pg-employees-card-picture-wp">
                      {/* profile pic */}
                      <img
                        className="edit-employees-pg-employees-card-picture"
                        src={profile.pictureURL ? profile.pictureURL : logo}
                        alt=""
                      />
                    </div>

                    <div className="edit-employees-pg-employees-card-desc-wp">
                      {/* description */}
                      <div className="edit-employees-pg-employees-card-desc-name-wp">
                        {/* Name */}
                        <h2 className="edit-employees-pg-employees-card-desc-name">{`${profile.first_name} ${profile.last_name}`}</h2>
                        <div className="edit-employees-pg-employees-card-desc-title-wp">
                          {/* Title Label */}
                          <label>Title</label>
                          {/* Lawyer's Title */}

                          <label>{profile.law_title}</label>
                        </div>
                      </div>
                    </div>

                    {profile.account_status == "active" ? (
                      <div className="edit-employees-pg-employees-card-desc-buttons-wp">
                        {/* action button 1 */}
                        <a
                          href={`https://app.counselconnect.ca/profile/employee/${profile.handle}`}
                          style={{ marginRight: 50 }}
                        >
                          View Profile
                        </a>
                        <button
                          className="edit-employees-pg-employees-card-desc-edit-btn"
                          onClick={btnClick2}
                          value={profile.handle}
                        >
                          <Icon.Edit size={24} alt="edit" color="black" />
                        </button>

                        <button
                          className="edit-employees-pg-employees-card-desc-delete-btn"
                          onClick={() => {
                            setEmpDeleteData({
                              handle: profile.handle,
                              profileImg: profile.pictureURL,
                              firstName: profile.first_name,
                            });
                            onDeleteEmployee();
                          }}
                          value={profile.handle}
                        >
                          <Icon.UserMinus size={24} alt="x" color="#D01D1B" />
                        </button>
                      </div>
                    ) : (
                      <div style={{ display: "flex", flexFlow: "column" }}>
                        <span>Deactivated</span>
                        <small>Contact us, if you have any questions.</small>
                      </div>
                    )}
                  </div>
                ))}
              </section>
            </>
          ) : (
            <>
              <span className="connections__none__title">Practitioners</span>
              <div className="connections__none">
                <span className="connections__none__txt">
                  You currently have no practitioners added
                </span>

                <div className="connections__none__btn" onClick={onNewEmployee}>
                  <span className="connections__none__btn__txt">
                    Add Lawyer
                  </span>
                  <Icon.UserPlus size={21} color={"#3B1212"} />
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <NewEmployeeModal
        modalId="new-employee"
        modalTitle="New Law Practitioner"
        {...props}
      />

      <UpdateEmployeeModal
        modalId="update-employee"
        modalTitle="Update Law Practitioner"
        employeeData={empData}
        {...props}
      />

      <DeleteEmployeeModal
        modalId="delete-employee"
        modalTitle="Remove Law Practitioner"
        employeeDeleteData={empDeleteData}
        {...props}
      />
    </div>
  );
};

export default withAlert()(Employees);
