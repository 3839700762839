import React, { useEffect, useState } from "react";

import useForm from "../../../hooks/useForm";
import SubNavbar from "../../reusable/sub_navbar/SubNavbar";
import TextField from "../../reusable/text_field/TextField";

import "./AccountInfo.scss";
import http from "../../../utils/http";
import { withAlert } from "react-alert";
import environment from "../../../utils/environment";
import PasswordReset from "./PasswordReset";

const AccountInfo = (props) => {
  const [isEdit, setIsEdit] = useState(false);

  const onSubmit = async (inputs) => {
    try {
      await http.post(`${environment.resolveApi().rest}/user/email`, {
        email: inputs.email,
      });
      props.alert.success("Email Updated!");
      props.history.push("/");
    } catch (error) {
      console.log(error);
      if (!error.message) {
        props.alert.error(error.email);
      }
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);

  const [email, setEmail] = useState();

  const getUserInfo = async () => {
    try {
      const response = await http.get(
        `${environment.resolveApi().rest}/user/info`
      );
      console.log(response.data.email);
      setEmail(response.data.email);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="AccountInfo">
      <SubNavbar title="Account Info" config="info" />

      <div className="acc-info-content-container">
        <form onSubmit={handleSubmit}>
          <div className="acc-info-email">
            <div className="acc-info-email-txt-view">
              <strong>Email</strong>

              <span>{email}</span>
              {/* { !isEdit
                ? (<span>{email}</span>)
                : (
                  <div className="acc-info-edit-email-txt-field">
                    <TextField
                      className="lw-portal-text-input"
                      title=""
                      placeholder=""
                      type="email"
                      name="email"
                      value={inputs.email}
                      onChange={handleInputChange}
                      info="Enter new email"
                      lawPortal
                    />
                  </div>
                )
            }
            </div>

            { !isEdit
              ? (<button className="acc-info-email-txt-edit" onClick={() => { setIsEdit(true); }}>Edit</button>)
              : (<button className="acc-info-email-txt-edit" type="submit">Update</button>)
          } */}
            </div>
          </div>
        </form>

        <PasswordReset />
      </div>
    </div>
  );
};

export default withAlert()(AccountInfo);
