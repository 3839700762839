import React, { useContext, useEffect, useState } from "react";
import http from "../../../utils/http";
import SubNavbar from "../../reusable/sub_navbar/SubNavbar";
import useForm from "../../../hooks/useForm";
import { withAlert } from "react-alert";
import "../contact/Contact.scss";
import environment from "../../../utils/environment";
import SubscriptionPlans from "../../dashboard/SubscriptionPlans";
import ReccurTypeToggle from "./reccur_type_toggle/ReccurTypeToggle";
import { PlanTypeContext } from "../../../contexts/PlanTypeContext";
import { ConnectionsContext } from "../../../contexts/ConnectionsContext";

const ManageSubscription = (props) => {
  const [reccurType, setReccurType] = useState("Monthly");
  const { planType, setPlanType } = useContext(PlanTypeContext);
  const { connections, setConnections } = useContext(ConnectionsContext);

  const [resetComponent, setResetComponent] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const getUserPlan = async () => {
    try {
      const response = await http.get(
        `${environment.resolveApi().rest}/user/profile`
      );

      setPlanType(response.data.stripe.plan);
    } catch (error) {
      console.log(error);
    }
  };

  const isLitePlanUsedUp = async (connections) => {
    try {
      let numOfConnections = connections.length;
      console.log("numOfConnections", numOfConnections);

      // if firm has used up the lite plan
      if (numOfConnections > 6) {
        let visibleLiteConnections = connections.filter(function isVisible(
          connection
        ) {
          const createdAt = new Date(connection.createdAt);
          let connectYear = createdAt.getFullYear();

          if (connectYear == 2023) {
            return connection;
          }
        });

        console.log("visibleLiteConnections", visibleLiteConnections);

        if (visibleLiteConnections && visibleLiteConnections.length > 6) {
          console.log("plan is used up ***");
          setPlanUsedUp(true);
        }
      }

      // return result;
    } catch (error) {
      console.log(error);
    }
  };

  const downgradeToFree = async () => {
    try {
      setPageLoading(true);
      const response = await http.post(
        `${environment.resolveApi().rest}/payments/downgrade-to-free`
      );

      console.log("downgradeToFree - respoonse", response);

      setResetComponent(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserPlan();
    isLitePlanUsedUp(connections);
    setPageLoading(false);
  }, [resetComponent]);

  return (
    <div className="Contact">
      <SubNavbar title="Subscription" config="info" />
      <div className="contact-form-outer-container">
        <ReccurTypeToggle
          reccurType={reccurType}
          setReccurType={setReccurType}
        />

        {pageLoading ? (
          <div className="card-deck mb-3 text-center">Loading...</div>
        ) : (
          <SubscriptionPlans
            reccurType={reccurType}
            noFree={false}
            flow={"manage-plan"}
            setPlanType={setPlanType}
            pageLoading={pageLoading}
            setPageLoading={setPageLoading}
            downgradeToFree={downgradeToFree}
          />
        )}
      </div>
    </div>
  );
};

export default withAlert()(ManageSubscription);
