import React, { useEffect, useState, useCallback } from "react";
import http from "../../../utils/http";
import environment from "../../../utils/environment";
import SubNavbar from "../../reusable/sub_navbar/SubNavbar.jsx";
import SelectList from "../../reusable/select_list/SelectList";
import useForm from "../../../hooks/useForm";
import { withAlert } from "react-alert";

import "./addAvailability.scss";
import "../edit_profile/EditProfile.scss";

const timeOptions = [
  { label: "* Select Time", value: 0 },
  { label: "Closed", value: "Closed" },
  { label: "6:00 AM", value: "6:00 AM" },
  { label: "7:00 AM", value: "7:00 AM" },
  { label: "8:00 AM", value: "8:00 AM" },
  { label: "9:00 AM", value: "9:00 AM" },
  { label: "10:00 AM", value: "10:00 AM" },
  { label: "11:00 AM", value: "11:00 AM" },
  { label: "12:00 PM", value: "12:00 PM" },
  { label: "1:00 PM", value: "1:00 PM" },
  { label: "2:00 PM", value: "2:00 PM" },
  { label: "3:00 PM", value: "3:00 PM" },
  { label: "4:00 PM", value: "4:00 PM" },
  { label: "5:00 PM", value: "5:00 PM" },
  { label: "6:00 PM", value: "6:00 PM" },
  { label: "7:00 PM", value: "7:00 PM" },
  { label: "8:00 PM", value: "8:00 PM" },
  { label: "9:00 PM", value: "9:00 PM" },
  { label: "10:00 PM", value: "10:00 PM" },
];

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const EditAvailability = (props) => {
  const [isEdit, setIsEdit] = useState(false);

  const onSubmit = useCallback(async (inputs) => {
    // TODO: validation

    const office_hours = {
      ...days.reduce(
        (acc, day) => ({
          ...acc,
          [day]: {
            from: inputs[`${day}from`],
            to: inputs[`${day}to`],
          },
        }),
        {}
      ),
    };

    console.log(office_hours);

    if (
      !office_hours.monday.from ||
      !office_hours.monday.to ||
      !office_hours.tuesday.from ||
      !office_hours.tuesday.to ||
      !office_hours.wednesday.from ||
      !office_hours.wednesday.to ||
      !office_hours.thursday.from ||
      !office_hours.thursday.to ||
      !office_hours.friday.from ||
      !office_hours.friday.to ||
      !office_hours.saturday.from ||
      !office_hours.saturday.to ||
      !office_hours.sunday.from ||
      !office_hours.sunday.to
    ) {
      props.alert.error("Missing Office Hours!");
      return;
    }

    try {
      let response = await http.post(
        `${environment.resolveApi().rest}/law_firm/hours/update`,
        office_hours
      );

      props.alert.success("Availability Updated!");
      // getLawFirmInfo();
    } catch (error) {
      console.log(error);
    }
  });

  const { inputs, setInputs, handleInputChange, handleSubmit } =
    useForm(onSubmit);

  const getLawFirmInfo = async () => {
    try {
      const response = await http.get(
        `${environment.resolveApi().rest}/law_firm/info`
      );

      const availability = {
        ...days.reduce((acc, day) => {
          if (!response.data.law_firm.office_hours[day]) return acc;

          if (response.data.law_firm.office_hours[day].from)
            acc[`${day}from`] = response.data.law_firm.office_hours[day].from;
          if (response.data.law_firm.office_hours[day].to)
            acc[`${day}to`] = response.data.law_firm.office_hours[day].to;

          return acc;
        }, {}),
      };

      setInputs(availability);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLawFirmInfo();
  }, []);

  // early returns
  // if (loading) return <div>loading</div>;
  // if (!profile) return <div>no profile</div>;

  return (
    <div className="AddAvailability">
      <SubNavbar title="Office Hours" config="profile" />
      <div className="edit-avail-content-container">
        <div>
          <form className="edit-avail-form-container" onSubmit={handleSubmit}>
            <span className="edit-profile-c3-title">Edit Availability</span>
            <hr style={{ width: "100%" }}></hr>

            {days.map((day) => (
              <div key={day} className="edit-avail-input-container-1">
                <h3 className="edit-avail-day-text">
                  {day.charAt(0).toUpperCase() + day.slice(1)}
                </h3>
                <div className="edit-avail-select-container">
                  <div className="edit-avail-select-input-1">
                    <SelectList
                      className="law-portal-select-list-input"
                      title="From"
                      placeholder=""
                      name={`${day}from`}
                      value={inputs[`${day}from`]}
                      onChange={handleInputChange}
                      options={timeOptions}
                      info=""
                      lawPortal
                    />
                  </div>
                  <div className="edit-avail-select-input-2">
                    <SelectList
                      className="law-portal-select-list-input"
                      title="To"
                      placeholder=""
                      name={`${day}to`}
                      value={inputs[`${day}to`]}
                      onChange={handleInputChange}
                      options={timeOptions}
                      info=""
                      lawPortal
                    />
                  </div>
                </div>
              </div>
            ))}

            <div className="edit-avail-update-button-container">
              <button type="submit" className="edit-avail-update-button">
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* ) : (
          <div className="availability-view-content-container">
            <div className="profile-info-tag-container">
              <div className="profile-info-tag-content-container">
                <h6>Availability</h6>
                <hr />
                <div className="availaibility-tag-heading-container">
                  {
                    days.map((day) => {
                      const from = inputs[day].from;
                      const to = inputs[day].to;
                      return (
                        <div key={day}>
                          {  from === "Closed" && to === "Closed" ?
                           <div>
                              <strong>{day.charAt(0).toUpperCase() + day.slice(1)}</strong>
                              <h5>{`${from}`}</h5> 
                           </div>   
                              :
                           <div>   
                              <strong>{day.charAt(0).toUpperCase() + day.slice(1)}</strong>
                              <h5>{`${from} - ${to}`}</h5>
                            </div>
                          }
                        </div>
                      );
                    })
                  }
    

                </div>
              </div>
            </div>

            <div className="availability-view-content-button-container">
              <button className="availability-view-content-button" onClick={() => setIsEdit(true)}>EDIT</button>
            </div>

          </div>
        )
      } */}
    </div>
  );
};

export default withAlert()(EditAvailability);
