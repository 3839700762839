import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";

// import { getCurrentProfile } from '../../actions/profileActions';
import Spinner from "../reusable/spinner/Spinner";
import http from "../../utils/http";
import "./style/dashboard.scss";
import iconProfile from "../layout/navbar/images/profile-icon.svg";
import environment from "../../utils/environment";
import { logoutUser } from "../../actions/authActions";
import * as Icon from "react-feather";
import SubscriptionPlans from "./SubscriptionPlans";
import CreateProfile from "../profile/create-profile/CreateProfile";
import { PlanSelectedContext } from "../../contexts/PlanSelectedContext";

import "./SubscriptionPlans.scss";
import { GET_ERRORS, SET_CURRENT_USER } from "../../actions/types";
import { PlanTypeContext } from "../../contexts/PlanTypeContext";

import { useHistory } from "react-router";

const Dashboard = (props) => {
  let { history } = props;

  const isProfileRoute = (match, location) =>
    ["/edit-profile", "/edit-hours", "/edit-employees"].includes(
      location.pathname
    );

  // const history = useHistory();
  const [isLawFirmActive, setIsLawFirmActive] = useState(true);
  const [lawFirmInfo, setLawFirmInfo] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [reccurType, setReccurType] = useState("Monthly");
  // const [planSelected, setPlanSelected] = useState(false);
  const uploadRef = useRef(null);
  const [currentTab, setCurrentTab] = useState("Dashboard");
  const PLAN_SELECTED_CONTEXT = useContext(PlanSelectedContext);

  const getLawFirmInfo = async () => {
    try {
      const response = await http.get(
        `${environment.resolveApi().rest}/law_firm/info`
      );
      setLawFirmInfo(response.data.law_firm);

      // if law firm is deactivated
      if (response.data.law_firm.account_status == "deactivated") {
        setIsLawFirmActive(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isUserPremium = async () => {
    try {
      let response = await http.get(
        `${environment.resolveApi().rest}/payments/is-user-lite`
      );

      // if user has premium
      if (response.data.has_premium) {
        PLAN_SELECTED_CONTEXT.setPlanSelected(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("props - dashboard", props);
    getLawFirmInfo();
    isUserPremium();
  }, []);

  const logoutUser = () => async (dispatch) => {
    try {
      await http.post(`${environment.resolveApi().rest}/user/logout`);

      dispatch({
        type: SET_CURRENT_USER,
        payload: false,
      });

      window.history.push("/login");
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    }
  };

  // early returns
  if (lawFirmInfo && !isLawFirmActive) {
    return (
      <div className="accountDeactivated">
        <div className="accountDeactivated__content">
          <span>account is deactivated, contact us for more info.</span>

          <div className="navbar-logout-container">
            <span onClick={logoutUser}>
              <span>Logout</span>
              <Icon.LogOut
                className="navbar-logout-icon"
                size={28}
                alt="Logout"
              />
            </span>
          </div>
        </div>
      </div>
    );
  }

  // if (loading) return <Spinner />;
  if (PLAN_SELECTED_CONTEXT.planSelected && !lawFirmInfo) {
    return <CreateProfile props={props} />;
  }

  // if (loading) return <Spinner />;
  if (!PLAN_SELECTED_CONTEXT.planSelected && !lawFirmInfo) {
    return (
      <div
        className="no-profile-dashboard-container"
        style={{
          paddingTop: 120,
          width: "100% !important",
          display: "flex",
          flexFlow: "column",
          // alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: 24,
            fontWeight: 600,
            marginTop: 10,
            textAlign: "center",
          }}
        >
          Welcome to CounselConnect!
        </p>
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: 18,
            fontWeight: 400,
            marginTop: 10,
            marginTop: -10,
            marginBottom: 50,
            textAlign: "center",
          }}
        >
          Before, you go ahead and create your profile, select a plan:
        </p>

        <div className="subPlans__reccur">
          <div
            className={`subPlans__reccur__btn${
              reccurType == "Monthly" ? "--active" : "--default"
            } subPlans__reccur__btn--monthly`}
            onClick={() => {
              setReccurType("Monthly");
            }}
          >
            <span
              className={`subPlans__reccur__btn__txt${
                reccurType == "Monthly" ? "--active" : "--default"
              } subPlans__reccur__btn__txt--monthly`}
            >
              Monthly
            </span>
          </div>
          <div
            className={`subPlans__reccur__btn${
              reccurType == "Annual" ? "--active" : "--default"
            } subPlans__reccur__btn--annual`}
            onClick={() => {
              setReccurType("Annual");
            }}
          >
            <span
              className={`subPlans__reccur__btn__txt${
                reccurType == "Annual" ? "--active" : "--default"
              } subPlans__reccur__btn__txt--annual`}
            >
              Annual - 15% off
            </span>
          </div>
        </div>
        <SubscriptionPlans
          reccurType={reccurType}
          noFree={false}
          flow={"new-profile"}
        />
      </div>
    );
  }

  // const { handle, status, field, city } = profile;

  return (
    <div className="Dashboard">
      {/* <h1>{lawFirmInfo.name}</h1> */}

      <div className="dashboard-row-1">
        <div className="dashboard-row-1-card-2 ">
          <img
            className="dashboard-row-1-card-2-image"
            src={require("./images/walkingMan.svg")}
            alt=""
          ></img>
          <div className="dashboard-row-1-card-2-title-container">
            <span className="dashboard-row-1-card-2-title">
              👋 Hello {lawFirmInfo.name}!
            </span>
            <span className="dashboard-row-1-card-2-message">
              Below are quick links to manage your profile, practitioners, and
              connections.
            </span>
          </div>
        </div>
      </div>

      <div className="dashboard-row-2">
        <div className="dashboard-social-profile-card">
          <h2>Law Firm Profile</h2>
          {lawFirmInfo.companyLogo ? (
            <img
              style={{ width: "110px" }}
              className="profile-view-image"
              src={lawFirmInfo.companyLogo}
              alt=""
            />
          ) : (
            <img
              className="law-clients-icon"
              src={require("./images/dashboard-edit-profile-pic.svg")}
              alt=""
            />
          )}
          {/* <Link to="edit-profile" style={{ textDecoration: "none" }}  > */}

          <button
            className="dashboard-social-profile-button"
            onClick={() => {
              setCurrentTab("Firm Profile");
              history.push("/edit-profile");
            }}
          >
            EDIT
          </button>

          {/* </Link> */}
        </div>

        <div className="dashboard-social-profile-card">
          <h2>Law Pactitioners Profiles</h2>

          <img
            className="law-clients-icon"
            src={require("./images/team.png")}
            alt=""
          />

          <button
            className="dashboard-social-profile-button"
            onClick={() => {
              setCurrentTab("Practitioners");
              history.push("/employees");
            }}
          >
            VIEW
          </button>
        </div>

        <div className="dashboard-social-profile-card">
          <h2>Client Connections</h2>

          <div>
            <img
              style={{ width: "60px" }}
              className="law-clients-icon"
              src={require("./images/mail_icon.png")}
              alt=""
            />
          </div>

          <button
            className="dashboard-social-profile-button"
            onClick={() => {
              setCurrentTab("Practitioners");
              history.push("/connections");
            }}
          >
            VIEW
          </button>
        </div>
      </div>

      <div className="dashboard-row-3"></div>
    </div>
  );
};

export default Dashboard;
