import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import TextField from "../../reusable/text_field/TextField.jsx";
import TextAreaField from "../../reusable/text_area_field/TextAreaField";
import SelectList from "../../reusable/select_list/SelectList";
import { createProfile } from "../../../actions/profileActions";
import http from "../../../utils/http";

import SubNavbar from "../../reusable/sub_navbar/SubNavbar";
import useForm from "../../../hooks/useForm";
import defaultPic from "../edit_employees/employee_default_pic.svg";
import spinner from "../edit_employees/spinner_2.gif";

import "./EditProfile.scss";
import environment from "../../../utils/environment";
import { withAlert } from "react-alert";

// TODO: errors
// TODO: pull out image uploading to action?

const occupationTypeOptions = [
  { label: "* Select", value: 0 },
  { label: "Sole Practitioner", value: "Sole Practitioner" },
  { label: "Law Firm", value: "Law Firm" },
];

// Select status options for professional status
const statusOptions = [
  { label: "* Select Status", value: 0 },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Paralegal", value: "Paralegal" },
];

// Select field options for field of work
const paralegalCategories = [
  { label: "* Select Category", value: 0 },
  { label: "Traffic Tickets", value: "Traffic Tickets" },
  { label: "Minor Offences", value: "Minor Offences" },
  { label: "Landlord or Tenant", value: "Landlord or Tenant" },
  { label: "Employment Law", value: "Employment Law" },
  { label: "Small Claims", value: "Small Claims" },
  { label: "Immigration Law", value: "Immigration Law" },
];

const lawyerCategories = [
  { label: "* Select Category", value: 0 },
  { label: "Criminal Law", value: "Criminal Law" },
  { label: "Commercial Law", value: "Commercial Law" },
  { label: "Civil Law", value: "Civil Law" },
  { label: "Employment Law", value: "Employment Law" },
  { label: "Family Law", value: "Family Law" },
  { label: "Immigration Law", value: "Immigration Law" },
  { label: "Real Estate", value: "Real Estate" },
];

// Select years of experience options for professional status
const citiesOptions = [
  { label: "* Select City", value: 0 },
  { label: "Toronto", value: "Toronto" },
  { label: "Mississauga", value: "Mississauga" },
  { label: "Ottawa", value: "Ottawa" },
  { label: "Burlington", value: "Burlington" },
  { label: "Brampton", value: "Brampton" },
  { label: "Kitchener", value: "Kitchener" },
  { label: "Markham", value: "Markham" },
  { label: "Scarborough", value: "Scarborough" },
  { label: "Sudbury", value: "Sudbury" },
  { label: "Windsor", value: "Windsor" },
  { label: "Hamilton", value: "Hamilton" },
  { label: "London", value: "London" },
  { label: "Kingston", value: "Kingston" },
];

const genderOptions = [
  { label: "* Select Gender", value: 0 },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const timeOptions = [
  { label: "* Select Time", value: 0 },
  { label: "Closed", value: "Closed" },
  { label: "6:00 AM", value: "6:00 AM" },
  { label: "7:00 AM", value: "7:00 AM" },
  { label: "8:00 AM", value: "8:00 AM" },
  { label: "9:00 AM", value: "9:00 AM" },
  { label: "10:00 AM", value: "10:00 AM" },
  { label: "11:00 AM", value: "11:00 AM" },
  { label: "12:00 PM", value: "12:00 PM" },
  { label: "1:00 PM", value: "1:00 PM" },
  { label: "2:00 PM", value: "2:00 PM" },
  { label: "3:00 PM", value: "3:00 PM" },
  { label: "4:00 PM", value: "4:00 PM" },
  { label: "5:00 PM", value: "5:00 PM" },
  { label: "6:00 PM", value: "6:00 PM" },
  { label: "7:00 PM", value: "7:00 PM" },
  { label: "8:00 PM", value: "8:00 PM" },
  { label: "9:00 PM", value: "9:00 PM" },
  { label: "10:00 PM", value: "10:00 PM" },
];

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const EditProfile = (props) => {
  const onSubmit = useCallback(async (inputs) => {
    const profileData = {
      companyLogo: inputs.companyLogo,

      name: inputs.name,
      website: inputs.website,
      linkedin: inputs.linkedin,

      city: inputs.city,
      postalcode: inputs.postalcode,
      address: inputs.address,
      phonenumber: inputs.phonenumber,
    };

    try {
      setLoading(true);
      await http.post(
        `${environment.resolveApi().rest}/law_firm/general/update`,
        profileData
      );
      props.alert.success("Law Firm Profile Updated!");
      getLawFirmInfo();
      setLoading(false);
    } catch (error) {
      if (error.name) {
        props.alert.error(error.name);
      }
      if (error.city) {
        props.alert.error(error.city);
      }
      if (error.phonenumber) {
        props.alert.error(error.phonenumber);
      }
      if (error.postalcode) {
        props.alert.error(error.postalcode);
      }
      if (error.address) {
        props.alert.error(error.address);
      }
    }
  }, []);

  const { inputs, setInputs, handleInputChange, handleSubmit } =
    useForm(onSubmit);
  const [hours, setHours] = useState({});

  const getLawFirmInfo = async () => {
    try {
      const response = await http.get(
        `${environment.resolveApi().rest}/law_firm/info`
      );
      setInputs(response.data.law_firm);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLawFirmInfo();
  }, []);

  const [picUrl, setPictureUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const uploadRef = useRef(null);

  const onImageClick = useCallback(() => {
    uploadRef.current.click();
  }, [uploadRef]);

  const onFileUpload = async (e) => {
    e.persist();

    const file = e.target.files[0];

    //  If the file size (calculated in byte form) is than 1MB
    if (file.size > 1.0e6) {
      return props.alert.error("IMAGE SIZE MUST BE LESS THAN 1MB");
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      return props.alert.error("IMAGE TYPE MUST BE PNG OR JPG");
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      const response = await http.uploadImage(
        `${environment.resolveApi().rest}/law_firm/upload`,
        formData
      );
      setLoading(false);
      setPictureUrl(response.data.pictureURL);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="EditProfile">
      <SubNavbar title="Edit Profile" config="profile" />
      <div
        style={{ padding: "40px 100px 40px 50px" }}
        className="profile-edit-content-wrapper"
      >
        {loading ? (
          <div
            style={{ marginLeft: "200px" }}
            className="modal-spinner-container"
          >
            <h2 className="modal-spinner-title">Loading</h2>
            <img
              className="modal-loading-spinner"
              src={spinner}
              alt="Loading..."
            />
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="edit-profile-container-3">
              <span className="edit-profile-c3-title">General Info</span>
              <hr style={{ width: "100%" }}></hr>

              <div className="edit-profile-c2-input-field-container">
                <div
                  className={"new-lawfirm-modal-top-w-profile-pic-w"}
                  onClick={onImageClick}
                >
                  <img
                    className="new-lawfirm-uploaded-pic"
                    src={picUrl ? picUrl : inputs.companyLogo}
                    alt=""
                    onClick={onImageClick}
                  />
                  <input type="file" onChange={onFileUpload} ref={uploadRef} />
                </div>

                <div className="edit-profile-c3-input-group">
                  <TextField
                    title="Law Firm Name"
                    placeholder=""
                    name="name"
                    value={inputs.name}
                    onChange={handleInputChange}
                    info="Company Name"
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c3-input-group">
                  <TextField
                    title="Website"
                    placeholder=""
                    name="website"
                    value={inputs.website}
                    onChange={handleInputChange}
                    info="Law firm website URL."
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c3-input-group">
                  <TextField
                    title="Linkedin"
                    placeholder=""
                    name="linkedin"
                    value={inputs.linkedin}
                    onChange={handleInputChange}
                    info="Law firm linkedin profile URL."
                    lawPortal
                  />
                </div>
              </div>
            </div>

            <div className="edit-profile-container-4">
              <span className="edit-profile-c4-title">Contact Info</span>
              <hr style={{ width: "100%" }}></hr>
              <div className="edit-profile-c4-input-field-container">
                <div className="edit-profile-c4-input-group">
                  <SelectList
                    title="City"
                    placeholder=""
                    name="city"
                    value={inputs.city}
                    onChange={handleInputChange}
                    options={citiesOptions}
                    info="City that you live in."
                    className="law-portal-select-list-input"
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c4-input-group">
                  <TextField
                    title="Postal Code"
                    placeholder=""
                    name="postalcode"
                    value={inputs.postalcode}
                    onChange={handleInputChange}
                    info="Postal Code."
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c4-input-group">
                  <TextField
                    title="Address"
                    placeholder=""
                    name="address"
                    value={inputs.address}
                    onChange={handleInputChange}
                    info="Work Address."
                    lawPortal
                  />
                </div>
                <div className="edit-profile-c4-input-group">
                  <TextField
                    title="Phone Number"
                    placeholder=""
                    name="phonenumber"
                    value={inputs.phonenumber}
                    onChange={handleInputChange}
                    info="Personal or law firm number."
                    lawPortal
                  />
                </div>
              </div>
            </div>

            <div className="edit-profile-update-button-container">
              <button type="submit" className="edit-profile-update-button">
                UPDATE INFO
              </button>
            </div>
          </form>
        )}
      </div>
    </div>

    //  <div className="edit-profile-container-4">
    //           <span className="edit-profile-c4-title">Office Hours</span>
    //           <hr style={{width: '100%'}}></hr>

    //           <div className="edit-avail-form-container-create-profile">
    //              {
    //               days.map(day => (
    //                 <div key={day} className="edit-avail-input-container-1-create-profile">
    //                   <h3 className="edit-avail-day-text">{day.charAt(0).toUpperCase() + day.slice(1)}</h3>
    //                   <div className="edit-avail-select-container-create-profile">
    //                     <div className="edit-avail-select-input-1">
    //                       <SelectList
    //                         className="law-portal-select-list-input law-portal-select-list-input-create-profile"
    //                         title="From"
    //                         placeholder=""
    //                         name={`${day}from`}
    //                         value={inputs[`${day}from`]}
    //                         onChange={handleInputChange}
    //                         options={timeOptions}
    //                         info=""
    //                         lawPortal
    //                       />
    //                     </div>
    //                     <div className="edit-avail-select-input-2">
    //                       <SelectList
    //                         className="law-portal-select-list-input law-portal-select-list-input-create-profile"
    //                         title="To"
    //                         placeholder=""
    //                         name={`${day}to`}
    //                         value={inputs[`${day}to`]}
    //                         onChange={handleInputChange}
    //                         options={timeOptions}
    //                         info=""
    //                         lawPortal
    //                       />
    //                     </div>
    //                   </div>
    //                 </div>
    //               ))
    //             }
    //         </div>
    //       </div>
    //       </div>
  );
};

export default withAlert()(EditProfile);
