import React, { useEffect, useState, useRef, useCallback } from "react";
import useForm from "../../../hooks/useForm";
import http from "../../../utils/http";
import TextField from "../../reusable/text_field/TextField.jsx";
import SelectList from "../../reusable/select_list/SelectList";
import TextAreaField from "../../reusable/text_area_field/TextAreaField";
import defaultPic from "./employee_default_pic.svg";
import Modal from "../../reusable/Modal";
import { withAlert } from "react-alert";
import environment from "../../../utils/environment";

import spinner from "./spinner_2.gif";

import "./NewEmployeeModal.scss";

const lawTitleOptions = [
  { label: "*", value: 0 },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Paralegal", value: "Paralegal" },
];

const genderOptions = [
  { label: "*", value: 0 },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const lawyerCategories = [
  { label: "Criminal Law", value: "Criminal Law" },
  { label: "Commercial Law", value: "Commercial Law" },
  { label: "Civil Law", value: "Civil Law" },
  { label: "Employment Law", value: "Employment Law" },
  { label: "Family Law", value: "Family Law" },
  { label: "Immigration Law", value: "Immigration Law" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Personal Injury Law", value: "Personal Injury Law" },
];

const paralegalCategories = [
  { label: "Traffic Tickets", value: "Traffic Tickets" },
  { label: "Minor Offences", value: "Minor Offences" },
  { label: "Landlord or Tenant", value: "Landlord or Tenant" },
  { label: "Employment Law", value: "Employment Law" },
  { label: "Small Claims", value: "Small Claims" },
  { label: "Immigration Law", value: "Immigration Law" },
];

const NewEmployeeModal = (props) => {
  // const [file, setFile] = useState('');
  const [picUrl, setPictureUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (inputs) => {
    const profileData = {
      pictureURL: picUrl,

      handle: inputs.firstName + inputs.lastName,

      first_name: inputs.firstName,
      last_name: inputs.lastName,
      email: inputs.email,
      phone_number: inputs.phoneNumber,

      law_title: inputs.lawTitle,
      law_services: selectedLawFields,

      bio: inputs.bio,

      gender: inputs.gender,
      languages: inputs.languages,
    };

    if (profileData.pictureURL === null) {
      return props.alert.error("PROFILE PICTURE IS REQUIRED");
    }

    try {
      await http.post(
        `${environment.resolveApi().rest}/employee_profile/create`,
        profileData
      );
      props.alert.success(`${profileData.first_name}'s Profile Created!`);
      // props.history.push('/');
      window.location.reload();
    } catch (error) {
      if (error.first_name) {
        props.alert.error('"FIRST NAME" IS REQUIRED');
      }
      if (error.last_name) {
        props.alert.error('"LAST NAME" IS REQUIRED');
      }
      if (error.email) {
        props.alert.error(error.email);
      }
      if (error.phone_number) {
        props.alert.error('"PHONE NUMBER" IS REQUIRED');
      }
      if (error.law_title) {
        props.alert.error('"LAW TITLE" IS REQUIRED');
      }
      if (error.law_services) {
        props.alert.error('"LAW SERVICES" ARE REQUIRED');
      }
      if (error.gender) {
        props.alert.error(error.gender);
      }
      if (error.languages) {
        props.alert.error('"LANGUAGES" IS REQUIRED');
      }
      if (error.pictureURL) {
        props.alert.error('"PROFILE PICTURE" IS REQUIRED');
      }
    }
  };

  const { inputs, setInputs, handleInputChange, handleSubmit } =
    useForm(onSubmit);

  const [selectedLawFields, setLawFields] = useState([]);

  const [lawFirmInfo, setLawFirmInfo] = useState(null);

  const uploadRef = useRef(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);

  const getLawFirmInfo = async () => {
    try {
      const response = await http.get(
        `${environment.resolveApi().rest}/law_firm/info`
      );
      setLawFirmInfo(response.data.law_firm);
    } catch (error) {
      console.log(error);
    }
  };

  const onImageClick = useCallback(() => {
    uploadRef.current.click();
  }, [uploadRef]);

  const onFileUpload = async (e) => {
    e.persist();

    const file = e.target.files[0];

    //  If the file size (calculated in byte form) is than 1MB
    if (file.size > 1.0e6) {
      return props.alert.error("IMAGE SIZE MUST BE LESS THAN 1MB");
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      return props.alert.error("IMAGE TYPE MUST BE PNG OR JPG");
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);

      const response = await http.uploadImage(
        `${environment.resolveApi().rest}/employee_profile/upload`,
        formData
      );

      setLoading(false);

      setPictureUrl(response.data.pictureURL);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLawFirmInfo();
  }, []);

  const handleChange = (selectedOption) => {
    setLawFields(selectedOption);
    groupFields(selectedOption);
  };

  const groupFields = (selectedOption) => {
    let arr = [];

    if (selectedOption != null) {
      for (let i = 0; i < selectedOption.length; i++) {
        arr.push(selectedOption[i].value);
      }
    }
    setLawFields(arr);
  };

  return (
    <div className="NewEmployeeModal">
      <Modal modalId={props.modalId}>
        {loading ? (
          <div className="modal-spinner-container">
            <h2 className="modal-spinner-title">Setting Profile Image</h2>
            <img
              className="modal-loading-spinner"
              src={spinner}
              alt="Loading..."
            />
          </div>
        ) : (
          <div className="new-emp-modal-container">
            <h1 className="modal-title modal-custom-title">New Employee</h1>

            {/* <h1 className="new-emp-modal-pg-title">New Employee</h1> */}
            <form className="new-emp-modal-form" onSubmit={handleSubmit}>
              <div className="new-emp-modal-top-w">
                {/* <span className="edit-profile-c3-title">Personal Info</span>
                <hr className="new-emp-modal-pg-line"></hr>
   */}

                {/* { profilePictureUrl ?
                     <img className="profile-view-image" src={profilePictureUrl} alt="" />
                     : */}

                <div
                  style={picUrl ? { borderRadius: "0px", border: "none" } : {}}
                  className={"new-emp-modal-top-w-profile-pic-w"}
                >
                  <img
                    className="new-emp-modal-uploaded-pic"
                    src={picUrl ? picUrl : defaultPic}
                    alt=""
                    onClick={onImageClick}
                  />
                  <input type="file" onChange={onFileUpload} ref={uploadRef} />
                </div>
                {/* } */}
                <div className="new-emp-modal-top-w-gen-info-w">
                  <div className="new-emp-modal-top-w-gen-info-name-w">
                    {/* First Name */}

                    <TextField
                      className="lw-portal-text-input"
                      title="First Name"
                      placeholder="* "
                      name="firstName"
                      value={inputs.firstName}
                      onChange={handleInputChange}
                      // error={errors.company}
                      lawPortal
                    />
                    {/* Last Name */}
                    <TextField
                      className="lw-portal-text-input"
                      title="Last Name"
                      placeholder="* "
                      name="lastName"
                      value={inputs.lastName}
                      onChange={handleInputChange}
                      // error={errors.company}
                      lawPortal
                    />
                  </div>
                  <div className="new-emp-modal-top-w-gen-info-contact-w">
                    {/* Email */}
                    <TextField
                      className="lw-portal-text-input"
                      title="Email"
                      placeholder="* "
                      name="email"
                      value={inputs.email}
                      onChange={handleInputChange}
                      // error={errors.company}
                      lawPortal
                    />
                    {/* Phone Number */}
                    <TextField
                      className="lw-portal-text-input"
                      title="Phone Number"
                      placeholder="* "
                      name="phoneNumber"
                      value={inputs.phoneNumber}
                      onChange={handleInputChange}
                      // error={errors.company}
                      lawPortal
                    />
                  </div>
                </div>
              </div>

              {/* MIDDLE */}
              <div className="new-emp-modal-middle-w">
                {/* <div>
                        <span className="edit-profile-c3-title">Occupation Info</span>
                        <hr className="new-emp-modal-pg-line"></hr>
                    </div>   */}
                <div className="new-emp-modal-middle-w-occ-info-w">
                  {/* Law Title */}
                  <SelectList
                    title="Law Title"
                    placeholder=""
                    name="lawTitle"
                    value={inputs.lawTitle}
                    onChange={handleInputChange}
                    options={lawTitleOptions}
                    // info="City that you live in."
                    className="law-portal-select-list-input"
                    lawPortal
                  />
                  {/* Law Services */}
                  <SelectList
                    title="Law Services"
                    name="lawField"
                    value={inputs.lawServices}
                    onChange={handleChange}
                    options={
                      inputs.lawTitle === "Paralegal"
                        ? paralegalCategories
                        : lawyerCategories
                    }
                    multiSelect
                  />
                </div>
              </div>

              {/* END */}

              <div className="new-emp-modal-bottom-w">
                {/* <span className="edit-profile-c3-title">Social Info</span>
                <hr className="new-emp-modal-pg-line"></hr> */}

                {/* Bio */}

                <TextAreaField
                  className="edit-profile-text-area-input"
                  title="Bio"
                  placeholder="Paste or type in here."
                  name="bio"
                  value={inputs.bio}
                  onChange={handleInputChange}
                  // error={errors.bio}
                  // info="Bio for your profile."
                />

                <div className="new-emp-modal-bottom-w-other-info-w">
                  {/* Gender */}
                  <SelectList
                    title="Gender"
                    placeholder=""
                    name="gender"
                    value={inputs.gender}
                    onChange={handleInputChange}
                    options={genderOptions}
                    // info="City that you live in."
                    className="law-portal-select-list-input"
                    lawPortal
                  />
                  {/* Languages */}
                  <TextField
                    className="lw-portal-text-input"
                    title="Languages"
                    placeholder="* "
                    name="languages"
                    value={inputs.languages}
                    onChange={handleInputChange}
                    // error={errors.company}
                    lawPortal
                  />

                  {/* Create Profile Button */}
                  <div className="">
                    <button className="modal-button" type="submit">
                      CREATE
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default withAlert()(NewEmployeeModal);
