import React from 'react';
import './location.scss';
import { withAlert } from "react-alert";

const citiesOptions = [
  { label: 'Toronto', value: 'Toronto' },
  { label: 'Mississauga', value: 'Mississauga' },
  { label: 'Milton', value: 'Milton' },
  { label: 'Hamilton', value: 'Hamilton' },
];

let city = '';

const Location = (props) => {

  const onSubmitFields = () => {
    if(city.length === '') {
      props.alert.error("Select a city!");
    } else {
      props.history.push({pathname: '/search_by_status', state: { cityState: city }});
    }
  };

  const btnClick = (event) => {
    if (event) event.preventDefault();
    city = event.currentTarget.value;  
    onSubmitFields();
  };

  return(
  <div className="Location">
    <h1 className="location-search-title">City Locations</h1>
    <p className="location-search-subtitle">Select the city location that best for you.</p>
    <div className="law-field-options">
      {/* {citiesOptions.map((field, index) => (
          <button type="submit" className="status-search-lawyer-button" onClick={btnClick} key={index.id} value={field.value}>{field.value}</button>
      ))} */}
      <button type="submit" className="status-search-lawyer-button" onClick={btnClick} value="Mississauga">Mississauga</button>
    </div>
    <span className="location-search-subtitle-msg">Toronto, Milton, and Hamilton, coming soon...</span>
  </div>
);
};

export default withAlert()(Location);
