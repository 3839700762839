import React, { useState, useEffect, useContext, useCallback } from "react";
import "./ModalWrapper.scss";
import Modal from "react-modal";
import Subscription from "../../subscription/Subscription";
import SubscriptionPlans from "../../dashboard/SubscriptionPlans";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    padding: 0,
    overflowY: "scroll",
    height: "80%",
    width: "60%",
    maxHeight: "calc(100% - 20px)",
    background: "#FFF",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 3,
    overflowY: "scroll",
  },
};

export function ModalWrapper(props) {
  const { modalView, headerLabel, onClick } = props;

  const [reccurType, setReccurType] = useState("Monthly");

  useEffect(() => {
    Modal.setAppElement("#modal");
  }, []);

  const upgrade = (
    <div className="modalWrapper__upgrade">
      <span className="modalWrapper__upgrade__title">
        Unlock your new connections now!
      </span>

      <div className="subPlans__reccur">
        <div
          className={`subPlans__reccur__btn${
            reccurType == "Monthly" ? "--active" : "--default"
          } subPlans__reccur__btn--monthly`}
          onClick={() => {
            setReccurType("Monthly");
          }}
        >
          <span
            className={`subPlans__reccur__btn__txt${
              reccurType == "Monthly" ? "--active" : "--default"
            } subPlans__reccur__btn__txt--monthly`}
          >
            Monthly
          </span>
        </div>
        <div
          className={`subPlans__reccur__btn${
            reccurType == "Annual" ? "--active" : "--default"
          } subPlans__reccur__btn--annual`}
          onClick={() => {
            setReccurType("Annual");
          }}
        >
          <span
            className={`subPlans__reccur__btn__txt${
              reccurType == "Annual" ? "--active" : "--default"
            } subPlans__reccur__btn__txt--annual`}
          >
            Annual - 15% off
          </span>
        </div>
      </div>
      <SubscriptionPlans
        reccurType={reccurType}
        noFree={true}
        flow={"upgrade-plan"}
      />
    </div>
  );

  const renderView = (modalView, props) => {
    if (modalView == "upgrade") {
      return upgrade;
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
    >
      <div className="ModalWrapper" style={{ flexGrow: 1 }}>
        {/* <ModalHeader
          onRequestClose={props.onRequestClose}
          headerLabel={headerLabel}
        /> */}

        {/* BODY CONTENT */}
        {renderView(modalView, props)}
      </div>
    </Modal>
  );
}
