import React, { useState, useEffect } from 'react';
import http from '../../utils/http';
import useForm from '../../hooks/useForm';
// import EmployeeProfileItem from '../profiles/EmployeeProfileItem';
import ProfileItem from '../profiles/ProfileItem';
import TextField from '../reusable/text_field/TextField.jsx';
import { withAlert } from "react-alert";
import './location.scss';
import environment from '../../utils/environment';

const arr = [];
// const type = 'Law Firm';
let lawFirmId = {};
let lawFirmEmployeeHandles = [];

const Results = (props) => {
    const { city, status, field, gender, type } = props.match.params;
    console.log(city, status, field, gender, type);
 

    const onSubmit = async (inputs) => {
      if (!inputs.email || inputs.email.length === 0) {
        props.alert.error("Missing Email!");
        return console.error('missing message');
      }
      try {
        const payload = { email: inputs.email };
        await http.post(`${environment.resolveApi().rest}/mailing/getupdate/${status}/${arr}/`, payload);
        props.alert.success("Message Sent!");
        props.history.push('/');
      } catch (error) {
        console.error('Sending Error!', error);
      }
    };
  
    
    const [profiles, getProfiles] = useState([{}]);
    const [employeeProfiles, setEmployeeProfiles] = useState([{}]);
    const [lawFirms, setFirmInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);

    const getList = async () => {
      try {
        arr.push(field);
        // console.log(arr);
        const response = await http.get(`${environment.resolveApi().rest}/profile/search/${city}/${status}/${arr}/${gender}`);
        getProfiles(response.data.profiles);
      }  catch (error) {
          console.log(error);
      }
    };

    const getEmployeeList = async () => {
      try {
        arr.push(field);
        // console.log(arr);
        const response = await http.get(`${environment.resolveApi().rest}/profile/searchEmployees/${status}/${arr}/${gender}`);
        setEmployeeProfiles(response.data.employee_profiles);
        getLawFirmInfo(response.data.employee_profiles);
      }  catch (error) {
          console.log(error);
      }
    };

    // Given a list of employee profiles, use the law_firm_id that associated 
    // with each profile id and output an array of law_firm_ids excluding
    // any duplicate law_firm_ids
    const getLawFirmInfo = async (profiles) => {
      try 
      {
        console.log(profiles);
        let arr = [];

        for (let i = 0; i < profiles.length; i++) {
          // const response = await http.get(`${environment.resolveApi().rest}/profile/law_firm/${profiles[i].law_firm_info}`);
          // console.log(response.data.law_firm);
          
          const index = arr.indexOf(profiles[i].law_firm_info);

          console.log(index);
          // if the firm id is not in the current array
          if (index === -1) { 
            arr.push(profiles[i].law_firm_info);
          } 
          console.log(arr);
        }

        let lawFirmsArr = [];

        for (let i = 0; i < arr.length; i++) {
          const response = await http.get(`${environment.resolveApi().rest}/profile/law_firm/${arr[i]}`);
          lawFirmsArr.push(response.data.law_firm);
          console.log(lawFirmsArr);
        }

        setFirmInfo(lawFirmsArr);
        
      } catch (error) {
        console.log(error);
      }
    }; 

    useEffect( () => {
    
      if (type !== 'Law Firm') {
        setLoading(true);
        getList();
        setLoading(false);
      } else {
        setLoading(true);
        getEmployeeList();
        setLoading(false);
      }
     
    }, [setLoading,getList,setLoading,getEmployeeList])


    const onSubmitFields = () => {
    
      props.history.push({pathname: `/search_firm`, state: { lawFirmId: lawFirmId, lawFirmEmployeeHandles: lawFirmEmployeeHandles } });
    };

    const btnClick = (event) => {
      if (event) event.preventDefault();
      lawFirmId = event.currentTarget.value;  

      // pass the array of profile handles that were found in the search for that exact law firm
      console.log(employeeProfiles); 

      // 1) Loop through the employeeProfiles array
      // 2) Retrieve the employees associated with the exact same law firm
      // 3) Put them a seperate list
    
      for ( let i = 0; i < employeeProfiles.length; i++ ) {
       
        //if the employee is in the law firm that was clicked on
        if ( employeeProfiles[i].law_firm_info === lawFirmId ) { 
          lawFirmEmployeeHandles.push(employeeProfiles[i].handle);
          // console.log(employeeProfiles[i]);
        }
        
      }
      // console.log('Law firm employees: ' + lawFirmEmployeeHandles)
      onSubmitFields();
    }
    
  
  return (
    <div className="Results">
      { profiles.length > 0 ?
        <div className="result-container">
        <h1 className="location-search-title">{status}'s in {city}</h1>
        <p className="result-search-subtitle-1"><strong>Law Fields</strong> <i class="fas fa-chevron-right"></i> {field}</p>
        <p className="result-search-subtitle-2"><strong>Gender</strong>  <i class="fas fa-chevron-right"></i> {gender}</p>
       
       { !loading ? 
        <div>

          {type === 'Law Firm' ?
            <div>
              {/* {profiles.map((profile, index) => (
                  <EmployeeProfileItem key={index} profile={profile}  />
                )
              )} */}
                
              {lawFirms.map((firm) => (
                  <button  style={{ height: '200px', maxWidth: '300px'}} className="status-search-lawyer-button" onClick={btnClick} value={firm._id}>
                   <img style={{maxWidth: '170px'}} src={firm.companyLogo}/>
                   {/* <p>{firm.address}</p> */}
                 </button>
                )
              )}
            

            </div>
            :
            <div>
              {profiles.map((profile, index) => (
                  <ProfileItem key={index} profile={profile}  />
                )
              )}
            </div>
          }
         
        </div>  
        :
        <div>loading...</div>
        }
        </div>
        :
        <div className="result-empty-container">
           <h1 className="location-search-title">{`Sorry, currently no ${field} ${status}'s`}</h1>

          <h2 className="get-update-txt">Enter in your email to get updated!</h2>
          <form className="result-empty-form" onSubmit={handleSubmit}>
            <TextField
              className="login-portal-input"
              title="Email"
              placeholder=""
              name="email"
              type="email"
              value={inputs.email}
              onChange={handleInputChange}
              // error={errors.login}
            />
             <button type="submit" className="edit-avail-update-button">Submit</button>
          </form>
          <a href="https://app.counselconnect.ca/search_by_location">
            <button className="edit-avail-update-button edit-avail-return-button">Return Home</button>
          </a>

        </div>
      }
      
      
    </div>
  );
};

export default withAlert()(Results);
