import React from 'react';
import PropTypes from 'prop-types';

// import './style/dashboard.scss';
import './credentialsTable.scss';

function CredentialsTable(props) {
  const {
    items,
    onDeleteItem,
    columns,
    getRowFromItem,
  } = props;

  return (
    <div>
      {
        !items.length ? <p>No data</p> : (
          <table className="table">
            <thead id="table-header">
              <tr>
                {columns.map(header => <th>{header}</th>)}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                items.map((item) => {
                  const { _id } = item;

                  const onDelete =  () => {
                      onDeleteItem(_id)
                      window.location.reload();
                  };
                  const rowData = getRowFromItem(item);

                  return (
                    <tr key={_id}>
                      {rowData.map(data => <td>{data}</td>)}
                      <td>
                        <button
                          type="submit"
                          className="btn btn-danger"
                          onClick={onDelete}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        )
      }
    </div>
  );
}

CredentialsTable.propTypes = {
  onDeleteItem: PropTypes.func,
  items: PropTypes.array,
};

CredentialsTable.defaultProps = {
  items: [],
  onDeleteItem() {},
};

export default CredentialsTable;
