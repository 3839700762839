import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import logo from "./images/side-navbar-logo.svg";
import * as Icon from "react-feather";
import environment from "../../../utils/environment";
import http from "../../../utils/http";
import microtip from "microtip/microtip.css";

import { getCurrentProfile } from "../../../actions/profileActions";

import "./Navbar.scss";

const isProfileRoute = (match, location) =>
  ["/edit-profile", "/edit-hours", "/edit-employees"].includes(
    location.pathname
  );

const isInfoRoute = (match, location) =>
  ["/account", "/subscription", "/contact"].includes(location.pathname);

const isDashboardRoute = (match, location) => ["/"].includes(location.pathname);

const isConnectionsRoute = (match, location) =>
  ["/connections"].includes(location.pathname);

const isEmployeeRoute = (match, location) =>
  ["/employees"].includes(location.pathname);

const Navbar = (props) => {
  const logout = () => {
    props.logoutUser();
  };

  const [homeLink] = useState(false);
  const [lawFirmInfo, setLawFirmInfo] = useState(null);
  const [currentTab, setCurrentTab] = useState("Dashboard");

  const getLawFirmInfo = async () => {
    try {
      const response = await http.get(
        `${environment.resolveApi().rest}/law_firm/info`
      );
      setLawFirmInfo(response.data.law_firm);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLawFirmInfo();
  }, []);

  // runs only on mount
  // useEffect(() => {
  //   getCurrentProfile();
  // }, [getCurrentProfile]);

  // /profile/me

  return (
    <div className=" sidebar">
      <div className="navbar-logo-container">
        <NavLink
          to="/"
          onClick={() => {
            setCurrentTab("Dashboard");
          }}
        >
          <img src={logo} alt="Home" width={170} />
        </NavLink>
      </div>

      <div className="sidebar__content">
        <div className="sidebar__content__links">
          {lawFirmInfo && (
            <NavLink
              exact
              to="/"
              isActive={isDashboardRoute}
              className="navbar-link"
              activeClassName="selected"
              onClick={() => {
                setCurrentTab("Dashboard");
              }}
            >
              <Icon.Home
                className="navbar-icon-default"
                activeClassName="iconSelected"
                size={24}
                alt="Users"
                color={currentTab == "Dashboard" ? "#D0021B" : "#9094a1"}
              />
              <span
                className={`sidebar__content__links__txt--${
                  currentTab == "Dashboard" ? "active" : "default"
                }`}
              >
                Dashboard
              </span>
            </NavLink>
          )}

          {lawFirmInfo && (
            <NavLink
              exact
              to="/connections"
              isActive={isConnectionsRoute}
              className="navbar-link"
              activeClassName="selected"
              onClick={() => {
                setCurrentTab("Connections");
              }}
            >
              <Icon.Inbox
                className="navbar-icon-default"
                activeClassName="iconSelected"
                size={24}
                alt="Users"
                color={currentTab == "Connections" ? "#D0021B" : "#9094a1"}
              />
              <span
                className={`sidebar__content__links__txt--${
                  currentTab == "Connections" ? "active" : "default"
                }`}
              >
                Connections
              </span>
            </NavLink>
          )}

          <hr style={{ marginTop: -17 }}></hr>

          {lawFirmInfo ? (
            <div>
              <NavLink
                exact
                to="/employees"
                isActive={isEmployeeRoute}
                className="navbar-link"
                activeClassName="selected"
                onClick={() => {
                  setCurrentTab("Practitioners");
                }}
              >
                <Icon.Users
                  className="navbar-icon-default"
                  activeClassName="iconSelected"
                  size={24}
                  color={currentTab == "Practitioners" ? "#D0021B" : "#9094a1"}
                />
                <span
                  className={`sidebar__content__links__txt--${
                    currentTab == "Practitioners" ? "active" : "default"
                  }`}
                >
                  Practitioners
                </span>
              </NavLink>
            </div>
          ) : (
            ""
          )}

          {lawFirmInfo ? (
            <div>
              <NavLink
                exact
                to="/edit-profile"
                isActive={isProfileRoute}
                className="navbar-link"
                activeClassName="selected"
                onClick={() => {
                  setCurrentTab("Firm Profile");
                }}
              >
                <Icon.Briefcase
                  className="navbar-icon-default"
                  activeClassName="iconSelected"
                  size={24}
                  color={currentTab == "Firm Profile" ? "#D0021B" : "#9094a1"}
                />
                <span
                  className={`sidebar__content__links__txt--${
                    currentTab == "Firm Profile" ? "active" : "default"
                  }`}
                >
                  Firm Profile
                </span>
              </NavLink>
            </div>
          ) : (
            ""
          )}

          <NavLink
            exact
            to="/account"
            isActive={isInfoRoute}
            className="navbar-link"
            activeClassName="selected"
            onClick={() => {
              setCurrentTab("Account Settings");
            }}
          >
            {/* <img src={iconProfile} alt="Profile" /> */}
            <Icon.Settings
              className="navbar-icon-default"
              activeClassName="iconSelected"
              size={24}
              color={currentTab == "Account Settings" ? "#D0021B" : "#9094a1"}
            />
            <span
              className={`sidebar__content__links__txt--${
                currentTab == "Account Settings" ? "active" : "default"
              }`}
            >
              Account Settings
            </span>
          </NavLink>
        </div>

        <div className="navbar__logout__btn" onClick={logout}>
          <span className="navbar__txt--default">Logout</span>

          <Icon.LogOut
            className="navbar-icon-default"
            activeClassName="iconSelected"
            size={24}
            color="#9094A1"
          />
        </div>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

Navbar.defaultProps = {
  profile: {},
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, { getCurrentProfile, logoutUser })(Navbar)
);
