import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '../../../reusable/text_field/TextField.jsx';
import Modal from '../../../reusable/Modal';
import { addEducation, getCurrentProfile } from '../../../../actions/profileActions';
import './addEducationModal.css';


class AddEducationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      school: '',
      degree: '',
      fieldofstudy: '',
      from: '',
      to: '',
      current: false,
      description: '',
      errors: {},
      disabled: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCheck = this.onCheck.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const eduData = {
      school: this.state.school,
      degree: this.state.degree,
      fieldofstudy: this.state.fieldofstudy,
      from: this.state.from,
      to: this.state.to,
      current: this.state.current,
      description: this.state.description,
    };

    this.props.addEducation(eduData, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCheck(e) {
    this.setState({
      disabled: !this.state.disabled,
      current: !this.state.current,
    });
  }


  render() {
    const { errors } = this.state;
    return (

      <div className="AddEducationModal">
        <Modal title={this.props.modalTitle} modalId={this.props.modalId}>
          <div className="edu-modal-outer-container">
            <form className="edu-modal-form" onSubmit={this.onSubmit}>
              <div className="edu-modal-form-container-1">

                <div className="edu-modal-item">
                  <TextField
                    className="lw-portal-text-input"
                    title="School"
                    placeholder="* School"
                    name="school"
                    value={this.state.school}
                    onChange={this.onChange}
                    error={errors.school}
                    lawPortal
                  />
                </div>

                <div className="edu-modal-item">
                  <TextField
                    className="lw-portal-text-input"
                    title="From Date"
                    name="from"
                    type="date"
                    value={this.state.from}
                    onChange={this.onChange}
                    error={errors.from}
                    lawPortal
                  />
                </div>

                <div className="edu-modal-item">
                  <TextField
                    className="lw-portal-text-input"
                    title="To Date"
                    name="to"
                    type="date"
                    value={this.state.to}
                    onChange={this.onChange}
                    error={errors.to}
                    disabled={this.state.disabled ? 'disabled' : ''}
                    lawPortal
                  />
                </div>

                <div className="form-check mb-4">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="current"
                    value={this.state.current}
                    checked={this.state.current}
                    onChange={this.onCheck}
                    id="current"
                  />
                  <label htmlFor="current" className="form-check-label">
                        Present
                  </label>
                </div>
              </div>

              <div className="edu-modal-form-container-2">

              <div className="edu-modal-item">
                  <TextField
                    className="lw-portal-text-input"
                    title="Degree"
                    placeholder="* Degree or Certification"
                    name="degree"
                    value={this.state.degree}
                    onChange={this.onChange}
                    error={errors.degree}
                    lawPortal
                  />
                </div>

                <div className="edu-modal-item">
                  <TextField
                    className="lw-portal-text-input"
                    title="Field"
                    placeholder="* Field of Study"
                    name="fieldofstudy"
                    value={this.state.fieldofstudy}
                    onChange={this.onChange}
                    error={errors.fieldofstudy}
                    lawPortal
                  />
                </div>

                {/* <div className="edu-modal-item">
                  <TextAreaField
                    className="modal-text-area-field"
                    title="Program Description"
                    placeholder=""
                    name="description"
                    value={this.state.description}
                    onChange={this.onChange}
                    error={errors.description}
                    info="Tell us about the program that you were in"
                    modalTextArea
                  />
                </div> */}
              </div>

            </form>

            <div className="modal-button-container">
              <button className="modal-button" onClick={this.onSubmit}>SUBMIT</button>
            </div>

          </div>
        </Modal>
      </div>

    );
  }
}

AddEducationModal.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  addEducation: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth,
  errors: state.errors,
});


export default connect(
  mapStateToProps,
  { addEducation, getCurrentProfile },
)(withRouter(AddEducationModal));
