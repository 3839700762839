import React, { useContext, useEffect, useState } from "react";

import * as Icon from "react-feather";
import "./ClientList.scss";
import http from "../../../utils/http";
import environment from "../../../utils/environment";
import dateFormat, { masks } from "dateformat";
import LETTER_COLORS from "../../reusable/LetterColors";
import { PlanTypeContext } from "../../../contexts/PlanTypeContext";

const clients = [
  {
    letter: "S",
    name: "Sarah Dennis",
    time: "Today at 4:30 PM",
    color: "#E9FBFF",
    email: "sarah.dennis@gmail.com",
    phone: "647-231-2112",
    message:
      "Hey, I is on 9th of May. So if you can help me out and get back to me I’d greatly appreciate it.",
  },
  {
    letter: "K",
    name: "Kevin Miller",
    time: "Yesterday at 8:30 PM",
    color: "#EBFFE9",
    email: "kevin@gmail.com",
    phone: "647-231-2112",
    message:
      "Hey, I have a case where I was trying to exchange money through forex exchange overseas with 2 transfers of $300 in November and 4 days ago I was contacted by Niagara police with 2 counts of fraud saying the money was never transferred from his account and my court date is on 9th of May. So if you can help me out and get back to me I’d greatly appreciate it.",
  },
  {
    letter: "J",
    name: "Jessica Alba",
    time: "Yesterday at 8:30 PM",
    color: "#FFE9E9",
    email: "jessica@gmail.com",
    phone: "647-231-2112",
  },
];

const ClientList = (props) => {
  let {
    setClient,
    clientSelected,
    connections,
    oldestConnection__index,
    setOldestConnection__index,
    setVisibleConnections,
  } = props;

  function getOldestObject(arr) {
    let oldestObj = arr[0];

    for (let i = 1; i < arr.length; i++) {
      if (arr[i].createdAt < oldestObj.createdAt) {
        oldestObj = arr[i];
      }
    }

    const index = arr.findIndex((obj) => obj === oldestObj);
    setOldestConnection__index(index);
    console.log("oldest", index);
    return index;
  }

  useEffect(() => {
    console.log("connections", connections);

    getOldestObject(connections);
  }, [connections]);

  return (
    <div className="clients-list">
      <div className="clients-list__header">
        <span className="clients-list__header__title">
          {connections.length} Client Connections
        </span>
        {/* <div className="clients-list__header__dropdown">
          <span className="clients-list__header__dropdown__txt">
            Sort by: Latest
          </span>

          <Icon.ChevronDown size={22} color={"#9094a1"} />
        </div> */}
      </div>

      <div className="clients-list__list">
        {connections.map((clientInfo, index) => {
          return (
            <>
              {oldestConnection__index == index && (
                <span className="clientsList__freeConnectLabel__txt">
                  Free Initial Connection
                </span>
              )}
              <div
                className={`clients-list__list__card ${
                  clientInfo.first_name == clientSelected.first_name &&
                  "clients-list__list__card--active"
                }`}
                onClick={() => {
                  setClient(clientInfo);
                }}
              >
                <div className="clients-list__list__card-left">
                  <div className="clients-list__list__card-left-container">
                    <div
                      className="clients-list__list__card__profile-circle"
                      style={{
                        backgroundColor:
                          LETTER_COLORS[
                            clientInfo.first_name.charAt(0).toUpperCase()
                          ].background,
                      }}
                    >
                      <span className="clients-list__list__card__profile-circle__txt">
                        {clientInfo.first_name &&
                          clientInfo.first_name.charAt(0)}
                      </span>
                    </div>
                    <div className="clients-list__list__card__name-and-time">
                      <span className="clients-list__list__card__name-and-time__name">
                        {clientInfo.first_name}
                      </span>
                      <div className="clients-list__list__card__name-and-time__time-container">
                        <Icon.Calendar size={16} color={"#9094A1"} />
                        <span className="clients-list__list__card__name-and-time__time__txt">
                          {dateFormat(clientInfo.createdAt, "mmmm dS, yyyy")}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="clientList__lawyerArrowWrap">
                    <Icon.ArrowRight size={21} color={"#3B1212"} />

                    <div className="clientList__lawyer">
                      <img
                        className="clientList__lawyer__img"
                        src={clientInfo.lawyer.picture_url}
                      />
                      <span className="clientList__lawyer__resultTxt">
                        Requesting{" "}
                        <span className="clientList__lawyer__resultTxt--Bold">
                          {clientInfo.lawyer.first_name}'s
                        </span>{" "}
                        services in{" "}
                        <span className="clientList__lawyer__resultTxt--Bold">
                          {clientInfo.service_request}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="clients-list__list__card-right-container">
                  {clientInfo.message ? (
                    <div className="clients-list__list__card__messages">
                      <div className="clients-list__list__card__message-icon-container">
                        <Icon.MessageCircle size={18} color={"#9094A1"} />
                        <div className="clients-list__list__card__message-icon__notify"></div>
                      </div>
                      <span className="clients-list__list__card__messages__label">
                        1
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}

                  <Icon.ChevronRight size={21} color={"#9094A1"} />
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ClientList;
