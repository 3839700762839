import React, { useEffect } from "react";
import PropTypes from "prop-types";
import http from "../../utils/http";
import useForm from "../../hooks/useForm";
import TextField from "../reusable/text_field/TextField";
import "./style/register.scss";
import logo from "./images/logo.svg";
import { withAlert } from "react-alert";
import environment from "../../utils/environment";

const SignUp = (props) => {
  const { shortId } = props.match.params;
  const { history } = props;

  const onSubmit = async (inputs) => {
    if (!(inputs.password1 === inputs.password2)) {
      props.alert.error("Password Don't Match!");
      return;
    }
    try {
      const newPasswordData = {
        userEmail: inputs.email,
        password: inputs.password1,
      };

      await http.post(
        `${environment.resolveApi().rest}/user/password/reset/${shortId}`,
        newPasswordData
      );
      props.alert.success("Password Updated!");
      history.push("/");
    } catch (error) {
      console.log(error);
      if (error.message) {
        props.alert.error(error.message);
      }
      if (error.password) {
        props.alert.error("Missing Input!");
      }
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);

  const fetchToken = async () => {
    try {
      await http.get(
        `${environment.resolveApi().rest}/mailing/password/reset/${shortId}`
      );
    } catch (error) {
      console.log(error);
      history.push("/");
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  return (
    <div className="SignUp">
      <div className="signup-header">
        <img className="side-navbar-home-icon" src={logo} alt="" />
        <h1 className="signup-title">Reset Password</h1>
        <p className="password-reset-reminder">
          Make sure your password contains a minimum of 6 characters containing
          numbers and special characters.
        </p>
      </div>

      <form
        onSubmit={handleSubmit}
        className="signup-form-container reset-password-container"
      >
        <TextField
          className="signup-input"
          title="Email"
          placeholder=""
          name="email"
          type="email"
          value={inputs.email}
          onChange={handleInputChange}
          // error={errors.email}
          autoComplete="off"
        />

        <div className="input-container">
          <TextField
            className="signup-input"
            title="Password"
            placeholder=""
            name="password1"
            type="password"
            value={inputs.password1}
            onChange={handleInputChange}
            // error={errors.password}
            autoComplete="off"
          />
        </div>
        <div className="input-container">
          <TextField
            className="signup-input"
            title="Confirm Password"
            placeholder=""
            name="password2"
            type="password"
            value={inputs.password2}
            onChange={handleInputChange}
            // error={errors.password}
            autoComplete="off"
          />
        </div>

        <button type="submit" className="signup-button">
          UPDATE
        </button>
      </form>

      <p className="text-muted copyright-signup">
        Copyright &copy; 2023 by CounselConnect
      </p>
    </div>
  );
};

export default withAlert()(SignUp);
