import * as Icon from "react-feather";
const profile = [
  {
    to: "/edit-profile",
    title: "Company Info",
    subtitle: "Edit law firm information",
    icon: <Icon.Edit size={21} color={"#9094A1"} />,
  },
  {
    to: "/edit-hours",
    title: "Office Hours",
    subtitle: "Edit availability",
    icon: <Icon.Clock size={21} color={"#9094A1"} />,
  },
];

const info = [
  {
    to: "/account",
    title: "Account Info",
    subtitle: "General account information",
    icon: <Icon.User size={21} color={"#9094A1"} />,
  },
  {
    to: "/subscription",
    title: "Subscription",
    subtitle: "Manage your subscription",
    icon: <Icon.DollarSign size={21} color={"#9094A1"} />,
  },
  {
    to: "/contact",
    title: "Contact",
    subtitle: "Have any questions?",
    icon: <Icon.Mail size={21} color={"#9094A1"} />,
  },
];

export default { info, profile };
