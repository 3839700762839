import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import environment from "./utils/environment";
import { checkLogin } from "./actions/authActions";
import store from "./utils/store";
import http from "./utils/http";
import Spinner from "./components/reusable/spinner/Spinner";
import SignUp from "./components/auth/SignUp";
import Login from "./components/auth/Login";
import SignUpRequest from "./components/auth/SignUpRequest";
import SignUpRequestSuccess from "./components/auth/SignUpRequestSuccess.jsx";
import Main from "./Main";
import PrivateRoute from "./components/reusable/PrivateRoute";
import Profile from "./components/law_profile/SocialProfile";
import ProfileEmployee from "./components/law_profile/ProfileEmployee";

import Location from "./components/client_search/Location.jsx";
import Status from "./components/client_search/Status.jsx";
import Lawyer from "./components/client_search/Lawyer.jsx";
import Paralegal from "./components/client_search/Paralegal.jsx";
import Gender from "./components/client_search/Gender.jsx";
import Type from "./components/client_search/Type.jsx";
import Results from "./components/client_search/Results.jsx";

import LawFirm from "./components/client_search/LawFirm.jsx";

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import PasswordResetRequest from "./components/auth/PasswordResetRequest.jsx";
import ResetPassword from "./components/auth/ResetPassword.jsx";
import NoPremiumLogin from "./components/no_premium_login/NoPremiumLogin";
import { PlanSelectedContext } from "./contexts/PlanSelectedContext";
import { PlanUsedUpContext } from "./contexts/PlanUsedUpContext";

const NETWORK_ERROR_CODE = 500;

// optional cofiguration
const options = {
  position: positions.TOP_RIGHT,
  timeout: 3000,
  offset: "50px",
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 9999,
  },
};

const App = () => {
  const [isChecking, setChecking] = useState(true);
  const [isConnected, setConnected] = useState(false);
  const [planSelected, setPlanSelected] = useState(false);

  // check if the server is up
  useEffect(async () => {
    try {
      // await http.get(`https://api.counselconnect.ca/`);
      setConnected(true);
      await store.dispatch(checkLogin());
    } catch (err) {
      // if GET / throws a network error, then server is unreachable
      // if (err.response.status !== NETWORK_ERROR_CODE) {
      //   setConnected(true);
      //   await store.dispatch(checkLogin());
      // }
    }

    // Run an setInterval every 15 seconds
    setInterval(() => {
      (async function () {
        const firmVersion = environment.resolveVersion().version;

        // Call API
        const response = await http.get(
          `${environment.resolveApi().rest}/admin/firm/version/${firmVersion}`
        );
        console.log(response.data);
        if (response.data == true) {
          console.log("NEW VERSION --- RELOADING CACHE NOW!");
          // Reload the current resources from the server
          alert("Version update!");
          window.location.reload(true);
        }
      })();
    }, 15000);

    setChecking(false);
  }, []);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    console.log(windowSize.current[0]);
    const initialValue = document.body.style.zoom;

    let windowWidth = windowSize.current[0];

    if (windowWidth > 767) {
      // Change zoom level on mount
      document.body.style.zoom = "85%";
    } else {
      // Change zoom level on mount
      document.body.style.zoom = "90%";
    }

    return () => {
      // Restore default value
      document.body.style.zoom = initialValue;
    };
  }, []);

  if (isChecking) return <Spinner />;
  // if (!isConnected) {
  //   return (
  //     <div>
  //       <h1>Can't connect to the server :(</h1>
  //       <p>(Make sure you have it running)</p>
  //     </div>
  //   );
  // }

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Provider store={store}>
        <PlanSelectedContext.Provider value={{ planSelected, setPlanSelected }}>
          <Router>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup/request" component={SignUpRequest} />

              <Route
                exact
                path="/no-premium-login"
                component={NoPremiumLogin}
              />
              <Route
                exact
                path="/signup/request/success"
                component={SignUpRequestSuccess}
              />
              <Route exact path="/signup/:shortId" component={SignUp} />
              <Route
                exact
                path="/reset-password"
                component={PasswordResetRequest}
              />
              <Route
                exact
                path="/reset-password/:shortId"
                component={ResetPassword}
              />

              <PrivateRoute path="/" component={Main} />
            </Switch>
          </Router>
          {/* </PlanUsedUpContext.Provider> */}
        </PlanSelectedContext.Provider>
      </Provider>
    </AlertProvider>
  );
};

export default App;
