import React, { useState } from "react";

const ReccurTypeToggle = (props) => {
  let { reccurType, setReccurType } = props;

  return (
    <div className="subPlans__reccur">
      <div
        className={`subPlans__reccur__btn${
          reccurType == "Monthly" ? "--active" : "--default"
        } subPlans__reccur__btn--monthly`}
        onClick={() => {
          setReccurType("Monthly");
        }}
      >
        <span
          className={`subPlans__reccur__btn__txt${
            reccurType == "Monthly" ? "--active" : "--default"
          } subPlans__reccur__btn__txt--monthly`}
        >
          Monthly
        </span>
      </div>
      <div
        className={`subPlans__reccur__btn${
          reccurType == "Annual" ? "--active" : "--default"
        } subPlans__reccur__btn--annual`}
        onClick={() => {
          setReccurType("Annual");
        }}
      >
        <span
          className={`subPlans__reccur__btn__txt${
            reccurType == "Annual" ? "--active" : "--default"
          } subPlans__reccur__btn__txt--annual`}
        >
          Annual - 15% off
        </span>
      </div>
    </div>
  );
};

export default ReccurTypeToggle;
