import React, { Component } from 'react';
import {withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '../../../reusable/text_field/TextField.jsx';
import Modal from '../../../reusable/Modal';
import { addExperience, getCurrentProfile } from '../../../../actions/profileActions';
import './addExperienceModal.css';


class AddExperienceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: '',
      title: '',
      location: '',
      from: '',
      to: '',
      current: false,
      description: '',
      errors: {},
      type: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCheck = this.onCheck.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentProfile();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const expData = {
      company: this.state.company,
      title: this.state.title,
      location: this.state.location,
      from: this.state.from,
      to: this.state.to,
      current: this.state.current,
      description: this.state.description,
    };

    this.props.addExperience(expData, this.props.history);
  
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onCheck(e) {
    this.setState({
      disabled: !this.state.disabled,
      current: !this.state.current,
    });
  }


  render() {
    const { errors } = this.state;
    return (

      <div className="AddExperienceModal">
        <Modal title={this.props.modalTitle} modalId={this.props.modalId}>
          <div className="exp-modal-outer-container">
            <form className="exp-modal-form" onSubmit={this.onSubmit}>


              <div className="exp-modal-form-container-1">

                <div className="exp-modal-item">
                  <TextField
                    className="lw-portal-text-input"
                    title="Company"
                    placeholder="* Company"
                    name="company"
                    value={this.state.company}
                    onChange={this.onChange}
                    error={errors.company}
                    lawPortal
                  />
                </div>
                <div className="exp-modal-item">
                  <TextField
                    className="lw-portal-text-input"
                    title="From Date"
                    name="from"
                    type="date"
                    value={this.state.from}
                    onChange={this.onChange}
                    error={errors.from}
                    lawPortal
                  />
                </div>
                <div className="exp-modal-item">
                  <TextField
                    className="lw-portal-text-input"
                    title="To Date"
                    name="to"
                    type="date"
                    value={this.state.to}
                    onChange={this.onChange}
                    error={errors.to}
                    disabled={this.state.disabled ? 'disabled' : ''}
                    lawPortal
                  />
                </div>

                <div className="form-check mb-4">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="current"
                    value={this.state.current}
                    checked={this.state.current}
                    onChange={this.onCheck}
                    id="current"
                  />
                  <label htmlFor="current" className="form-check-label">
                        Current Job
                  </label>
                </div>
              </div>

              <div className="exp-modal-form-container-2">
                <div className="exp-modal-item">
                  <TextField
                    className="lw-portal-text-input"
                    title="Location"
                    placeholder="Location"
                    name="location"
                    value={this.state.location}
                    onChange={this.onChange}
                    error={errors.location}
                    lawPortal
                  />
                </div>

                <div className="exp-modal-item">
                  <TextField
                    className="lw-portal-text-input"
                    title="Job Title"
                    placeholder="* Job Title"
                    name="title"
                    value={this.state.title}
                    onChange={this.onChange}
                    error={errors.title}
                    lawPortal
                  />
                </div>

                {/* <TextAreaField
                  className="modal-text-area-field"
                  title="Description"
                  placeholder="Job Description"
                  name="description"
                  value={this.state.description}
                  onChange={this.onChange}
                  error={errors.description}
                  modalTextArea
                  info="Tell us about the the position"
                /> */}


              </div>

            </form>

            <div className="modal-button-container">
              <button className="modal-button" onClick={this.onSubmit}>SUBMIT</button>
            </div>

          </div>
        </Modal>
      </div>

    );
  }
}

AddExperienceModal.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  addExperience: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth,
  errors: state.errors,
});


export default connect(
  mapStateToProps,
  { addExperience, getCurrentProfile },
)(withRouter(AddExperienceModal));
